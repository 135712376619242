import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import a from "../../Media/10SS.png";
import b from "../../Media/3SS.png";
import { MdCancel } from "react-icons/md";
import { useParams } from "react-router-dom";
import Modal from "../TeenPatti/Modal";
import { FaTrophy } from "react-icons/fa";
import { io } from "socket.io-client";
import { API } from "../../API/API";
import { useDispatch } from "react-redux";
import { fetchThirtyTwoCardStack } from "../../Redux/Slices/thirtyTwoCardGameSlice";

export default function FiveCricket() {
  const { id } = useParams();
  const uid = sessionStorage.getItem("userData");
  const token = sessionStorage.getItem("token");

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showresultModal, setShowresultModal] = useState(false);
  const [showDataModal, setShowdataModal] = useState(false);
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [realTime, setRealTimeData] = useState(false);
  const [betDetails, setbetDetails] = useState({});
  const [betIndex, setBetIndex] = useState("");
  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const [trophy, setTrophy] = useState("");

  const SOCKET_URL = "https://gamecoin-socket.nakshtech.info/";

  useEffect(() => {
    // console.log("Odd socket before");
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("poker socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        // console.log("parsed", parsedData[0]);
        // console.log("parsed_result", parsedData[0].resultUrlResponse);
        console.log("parsed_result name", parsedData[0].reqResponse);
        setResults(parsedData[0].resultUrlResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"></div>
        <div className="results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: 24200216501</div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
              }}
            />
          </div>
          <div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div ">
                <div className="text-center">Player A</div>
                <div className="d-flex">
                  <div className="gamecard-div mx-1">
                    <img src={b} className="img-fluid" alt="a" />
                  </div>
                  <div className="gamecard-div mx-1">
                    <img src={a} className="img-fluid" alt="a" />
                  </div>
                  <div className="gamecard-div mx-1">
                    <img src={b} className="img-fluid" alt="a" />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div  ">
                <div className="d-flex justify-content-between">
                  <div className="text-center">Player B</div>
                  <FaTrophy className="trophy-icon" />
                </div>

                <div className="d-flex">
                  <div className="gamecard-div mx-1">
                    <img src={b} className="img-fluid" alt="a" />
                  </div>
                  <div className="gamecard-div mx-1">
                    <img src={a} className="img-fluid" alt="a" />
                  </div>
                  <div className="gamecard-div mx-1">
                    <img src={b} className="img-fluid" alt="a" />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div ">
                <div className="text-center">Table</div>
                <div className="d-flex">
                  <div className="gamecard-div mx-1">
                    <img src={b} className="img-fluid" alt="a" />
                  </div>
                  <div className="gamecard-div mx-1">
                    <img src={a} className="img-fluid" alt="a" />
                  </div>
                  <div className="gamecard-div mx-1">
                    <img src={b} className="img-fluid" alt="a" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  async function ResultScheduler_API() {
    try {
      let res = await API.post("/Schulder_7CareGameLive", {
        gameid: id,
        username: uid
      },
        {
          headers: {
            Authorization: token,
          },
        });
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  const [history, setHistory] = useState([]);
  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);
    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id]);

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {/* {loading ? (
              <tr>
                <td colSpan="6">Loading ...</td>
              </tr>
            ) : */}
            {history.length > 0 ? (
              history.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );


  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nat: nat,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.sid === betIndex
      );
      console.log("filter", filteredData, betDetails);
      filteredData[0]?.status === "ACTIVE" &&
      (betDetails.multipler === filteredData[0].l1 ||
        betDetails.multipler === filteredData[0].b1)
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);


  return (
    <>
      <div className="col-lg-10 ">
        <div className="row home ">
          <div className="col-lg-8 p-1">
            <div className="teenpatti-game-div pt-2 p-2">
              <div className="teenpatti-game-h d-flex justify-content-between  ">
                <div className=" px-2">
                  <p className=" mt-1 ">5Five Cricketr</p>
                </div>
                <div>
                  <p className="mt-1 px-2">
                    Round ID: {reqRes?.data?.data?.t1[0]?.mid || 0.0} | Left
                    Time:{reqRes?.data?.data?.t1[0]?.autotime || 0} | Rules
                  </p>
                </div>
              </div>
              <div className="card  teenpatti-game-card p-1 mt-1">
                <div className="row">
                  <div className="col-10">
                    <div className="" style={{ height: "170%", width: "120%" }}>
                      <iframe
                        height="100%"
                        width="100%"
                        src={`https://battlemoney.live/iframe/${id}`}
                        title=""></iframe>
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-end">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>

              {/* <-------------------------------Back-lay-table----------> */}
              <div>
                <div className="table-h-div row">
                  <div className="col-6 d-flex align-items-center ps-2">
                    Bookmaker Market
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2">
                    Back
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center ">
                    Lay
                  </div>
                </div>
                <div className="card-table-inner-row row">
                  <div className="col-6 d-flex align-items-center ps-2">
                    {reqRes?.data?.data?.t2[0]?.nat}{" "}
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2">
                    {reqRes?.data?.data?.t2[0]?.status == "SUSPENDED" ||
                    reqRes?.data?.data?.t2[0]?.status == "CLOSED" ? (
                      <button className="back-btn">
                        {reqRes?.data?.data?.t2[0]?.status}
                      </button>
                    ) : (
                      <button
                        className="back-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t2[0]?.b1,
                            reqRes?.data?.data?.t2[0]?.nat,
                            1
                          );
                          setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                        }}>
                        {reqRes?.data?.data?.t2[0]?.b1}
                        <p>0</p>
                      </button>
                    )}
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center ">
                    {reqRes?.data?.data?.t2[0]?.status == "SUSPENDED" ||
                    reqRes?.data?.data?.t2[0]?.status == "CLOSED" ? (
                      <button className="lay-btn">
                        {reqRes?.data?.data?.t2[0]?.status}
                      </button>
                    ) : (
                      <button
                        className="lay-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t2[0]?.l1,
                            reqRes?.data?.data?.t2[0]?.nat,
                            3
                          );
                          setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                        }}>
                        {reqRes?.data?.data?.t2[0]?.l1}
                        <p>0</p>
                      </button>
                    )}
                  </div>
                </div>
                <div className="card-table-inner-row row">
                  <div className="col-6 d-flex align-items-center ps-2">
                  {reqRes?.data?.data?.t2[1]?.nat}
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2">
                  {reqRes?.data?.data?.t2[1]?.status == "SUSPENDED" ||
                    reqRes?.data?.data?.t2[1]?.status == "CLOSED" ? (
                      <button className="back-btn">
                        {reqRes?.data?.data?.t2[1]?.status}
                      </button>
                    ) : (
                      <button
                        className="back-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t2[1]?.b1,
                            reqRes?.data?.data?.t2[1]?.nat,
                            2
                          );
                          setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                        }}>
                        {reqRes?.data?.data?.t2[1]?.b1}
                        <p>0</p>
                      </button>
                    )}
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center ">
                  {reqRes?.data?.data?.t2[1]?.status == "SUSPENDED" ||
                    reqRes?.data?.data?.t2[1]?.status == "CLOSED" ? (
                      <button className="lay-btn">
                        {reqRes?.data?.data?.t2[1]?.status}
                      </button>
                    ) : (
                      <button
                        className="lay-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t2[1]?.l1,
                            reqRes?.data?.data?.t2[1]?.nat,
                            4
                          );
                          setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                        }}>
                        {reqRes?.data?.data?.t2[1]?.l1}
                        <p>0</p>
                      </button>
                    )}
                  </div>
                </div>
                {/* <div className="row  bg-dark mb-3">
                    <div className="Match-ods-h d-flex justify-content-between align-items-center">
                      <p className="mx-2 mt-3 d-flex">Bookmaker Market</p>
                    </div>
                    <div className="row back-lay-row  bg-dark ">
                      <div className="col-5 "></div>
                      <div className="col-7 coleft ">
                        <button className="back ">Back</button>
                        <button className="lay ms-1">Lay</button>
                      </div>
                    </div>
                    <div className="betting-team-info col-12">
                      <div className="row mt-1 bord">
                        <div className="col-md-5 betting-t-n ">
                          <p className="ms-2 mb-0 d-flex ">AUS</p>
                          <p className="ms-2">0</p>
                        </div>
                        <div className="col-md-7 d-flex">
                          <div className="blue-bet-btn blue-bet-btn2 ms-1 mb-0"></div>
                          <div className="blue-bet-btn blue-bet-btn1 ms-1 mb-0"></div>
                          <div className="blue-bet-btn ms-1 mb-0">
                            <div className="d-flex flex-column align-items-center mt-1">
                              1.22
                              <span className="fot">1M</span>
                            </div>
                          </div>
                          <div className=" orange-bet-btn ms-1 mb-0">
                            <div className="d-flex flex-column align-items-center mt-1">
                              1.26
                              <span className="fot">1M</span>
                            </div>
                          </div>
  
                          <div className="orange-bet-btn  orange-bet-btn1 ms-1 mb-0"></div>
                          <div className="orange-bet-btn orange-bet-btn2 ms-1 mb-0"></div>
                        </div>
                      </div>
  
                      <div className="row mt-1 bord">
                        <div className="col-md-5 betting-t-n ">
                          <p className="ms-2 mb-0 d-flex ">IND</p>
                          <p className="ms-2">0</p>
                        </div>
                        <div className="col-md-7 d-flex">
                          <div className="blue-bet-btn blue-bet-btn2 ms-1 mb-0"></div>
                          <div className="blue-bet-btn blue-bet-btn1 ms-1 mb-0"></div>
                          <div className="blue-bet-btn ms-1 mb-0">
                            <div className="d-flex flex-column align-items-center mt-1">
                              1.6
                              <span className="fot">1M</span>
                            </div>
                          </div>
                          <div className=" orange-bet-btn ms-1 mb-0">
                            <div className="d-flex flex-column align-items-center mt-1">
                              1.2
                              <span className="fot">1M</span>
                            </div>
                          </div>
  
                          <div className="orange-bet-btn  orange-bet-btn1 ms-1 mb-0"></div>
                          <div className="orange-bet-btn orange-bet-btn2 ms-1 mb-0"></div>
                        </div>
                      </div>
                    </div>
                  </div> */}
              </div>

              <div className="Results-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Last 10 Results</p>
                  </div>
                </div>
                <div className="card rst-card py-1 d-flex">
                  <div className="rst-row d-flex">
                    <div
                      onClick={() => {
                        setShowresultModal(true);
                      }}
                      className="rst-btn ms-1">
                      B
                    </div>
                    <div
                      onClick={() => {
                        setShowresultModal(true);
                      }}
                      className="rst-btn ms-1">
                      A
                    </div>
                    <div
                      onClick={() => {
                        setShowresultModal(true);
                      }}
                      className="rst-btn ms-1">
                      A
                    </div>
                    <div
                      onClick={() => {
                        setShowresultModal(true);
                      }}
                      className="rst-btn ms-1">
                      B
                    </div>
                    <div
                      onClick={() => {
                        setShowresultModal(true);
                      }}
                      className="rst-btn ms-1">
                      A
                    </div>
                    <div
                      onClick={() => {
                        setShowresultModal(true);
                      }}
                      className="rst-btn ms-1">
                      B
                    </div>
                    <div
                      onClick={() => {
                        setShowresultModal(true);
                      }}
                      className="rst-btn ms-1">
                      A
                    </div>
                    <div
                      onClick={() => {
                        setShowresultModal(true);
                      }}
                      className="rst-btn ms-1">
                      B
                    </div>
                    <div
                      onClick={() => {
                        setShowresultModal(true);
                      }}
                      className="rst-btn ms-1">
                      A
                    </div>
                    <div
                      onClick={() => {
                        setShowresultModal(true);
                      }}
                      className="rst-btn ms-1">
                      B
                    </div>
                    <div
                      onClick={() => {
                        setShowresultModal(true);
                      }}
                      className="rst-btn ms-1">
                      A
                    </div>
                  </div>
                </div>
              </div>

              {/* <<<<<<<---------My-Bets-table-------------->>>>>>>>>> */}
              <div className="My-Bets-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Open Bets</p>
                  </div>
                </div>
                {HistoryTable}
              </div>
            </div>
          </div>

          <RightSidebar />
          {showModal && (
            <Modal
              id={id}
              name={name}
              betDetails={betDetails}
              setbetDetails={setbetDetails}
              setShowModal={setShowModal}
              realTime={realTime}
              setRealTimeData={setRealTimeData}
            />
          )}
          {showresultModal && <ResultsModal />}
        </div>
      </div>
    </>
  );
}
