import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import "./AndarbaharNew.css";
import a from "../../Media/10SS.png";
import b from "../../Media/3SS.png";
import c1 from "../../Media/1.jpg";
import c2 from "../../Media/2.jpg";
import c3 from "../../Media/3.jpg";
import c4 from "../../Media/4.jpg";
import c5 from "../../Media/5.jpg";
import c6 from "../../Media/6.jpg";
import c7 from "../../Media/7.jpg";
import c8 from "../../Media/8.jpg";
import c9 from "../../Media/9.jpg";
import c10 from "../../Media/10.jpg";
import c11 from "../../Media/11.jpg";
import c12 from "../../Media/12.jpg";
import c13 from "../../Media/13.jpg";
import { MdCancel } from "react-icons/md";
import { FaTrophy } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../API/API";
import { io } from "socket.io-client";
import { fetchThirtyTwoCardStack } from "../../Redux/Slices/thirtyTwoCardGameSlice";
import Modal from "../TeenPatti/Modal";
import { card52War } from "../../Utility/52CardImages.js";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import cardBlank from "../../Media/DTL2020/backcard.webp";

export default function AndarbaharNew() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const imageImports = [c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, c11, c12, c13];
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [showresultModal, setShowresultModal] = useState(false);
  // const [showDataModal, setShowdataModal] = useState(false);
  const [rulesModal, setRulesModal] = useState(false);
  const [realTime, setRealTimeData] = useState(false);
  const [betIndex, setBetIndex] = useState("");
  const [betDetails, setbetDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const [initialIndex, setInitialIndex] = useState(0);

  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  console.log("casinoGameResult", casinoGameResult);

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      // console.log("CasinoGameResult andarB:", response.data.data[0]);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const ResultsModal = () => {
    const asteriskIndex = casinoGameResult.findIndex(
      (card) => card.Value === "*"
    );

    const anderCards = casinoGameResult.slice(0, asteriskIndex);
    const baharCards = casinoGameResult.slice(asteriskIndex + 1);

    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"
        ></div>
        <div
          className="results-modal-container bg-dark"
          style={{ width: "78%" }}
        >
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound}</div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
              }}
            />
          </div>
          <div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div ">
                <div className="text-center">Andar</div>
                <div className="d-flex">
                  {anderCards.map((card) => (
                    <div className="gamecard-div mx-1" key={card.id}>
                      <img
                        src={card.image}
                        className="img-fluid"
                        alt={`Player A Card ${card.id}`}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <div className="player-a-div  ">
                <div className="text-center">Bahar</div>

                <div className="d-flex">
                  {baharCards.map((card) => (
                    <div className="gamecard-div mx-1" key={card.id}>
                      <img
                        src={card.image}
                        className="img-fluid"
                        alt={`Player B Card ${card.id}`}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const SOCKET_URL = "https://gamecoin-socket.nakshtech.info/";

  useEffect(() => {
    // console.log("Odd socket before");
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("TeenPatti socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        console.log("parsed", parsedData[0].reqResponse);
        // console.log("parsed_result", parsedData[0].resultUrlResponse);
        setResults(parsedData[0].resultUrlResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  async function ResultScheduler_API() {
    try {
      let res = await API.post(
        "/Schulder_7CareGameLive",
        {
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  const [history, setHistory] = useState([]);

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);

    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [reqRes?.data?.data?.t1[0]?.mid]);

  useEffect(() => {
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch]);

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {/* {loading ? (
              <tr>
                <td colSpan="6">Loading ...</td>
              </tr>
            ) : */}
            {history.length > 0 ? (
              history.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nat: nat,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.sid === betIndex
      );
      filteredData[0]?.gstatus !== "0" &&
      betDetails?.nat === filteredData[0]?.nat &&
      betDetails?.multipler === filteredData[0]?.rate
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  const parseCardData = (cardData) => {
    return cardData.split(",").map((card) => {
      const cardName = card.trim(); // Extract card name and trim any whitespace
      return cardName;
    });
  };

  const andarData = parseCardData(reqRes?.data?.data?.t3[0]?.aall ?? "");
  const baharData = parseCardData(reqRes?.data?.data?.t3[0]?.ball ?? "");
  //console.log("ander", andarData, baharData);

  const displayedCardNumbers = {
    bahar: new Set(),
    andar: new Set(),
    // stack: [],
    cardSectionMap: new Map(),
  };

  const shouldDisplayCard = (card, section) => {
    const cardNumber = card.slice(0, -2);

    if (displayedCardNumbers.cardSectionMap.has(cardNumber)) {
      return displayedCardNumbers.cardSectionMap.get(cardNumber) === section;
    }

    displayedCardNumbers[section].add(cardNumber);
    displayedCardNumbers.cardSectionMap.set(cardNumber, section);

    console.log("displayedCardNumbers.stack", displayedCardNumbers.stack);

    return true;
  };

  const findCardImage = (cardId) => {
    const card = card52War.find((card) => card.id === cardId);
    return card ? card.src : null;
  };

  useEffect(() => {
    // Set the initial index to the last card in the array
    setInitialIndex(andarData.length > 3 ? andarData.length - 3 : 0);
    setInitialIndex(baharData.length > 3 ? baharData.length - 3 : 0);
  }, [andarData, baharData]);

  return (
    <>
      <div className="col-lg-10 ">
        <div className="row home mainhomeDev ">
          <div className="col-lg-8 p-1">
            <div className="teenpatti-game-div pt-2 p-2">
              <div className="teenpatti-game-h d-flex justify-content-between  ">
                <div className=" px-2">
                  <p className=" mt-1 ">{name}</p>
                </div>
                <div> 
                  <p className="mt-1 px-2">
                    Round ID: {reqRes?.data?.data?.t1[0]?.mid || 0.0} | Left
                    Time: {reqRes?.data?.data?.t1[0]?.autotime || 0}
                  </p>
                </div>
              </div>
              <div className="card  teenpatti-game-card p-1 mt-1">
                <div className="row">
                  <div className="col-10">
                    <div className="" style={{ height: "170%", width: "120%" }}>
                      <iframe
                        height="100%"
                        width="100%"
                        src={`https://battlemoney.live/iframe/${id}`}
                        title=""
                      ></iframe>
                    </div>  
                  </div>  
                  <div className="col-2 d-flex justify-content-end">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>  
                    </label>
                  </div>
                  <div className="timer ">
                    <div
                      className="container"
                      style={{
                        color: "white",
                        marginLeft: "-1px",
                        marginTop: "-150px",
                        width: "150px",
                        height: "auto",
                      }}
                    >
                      {/* <div className="timer_container position-absolute"  > */}
                      <div>
                        <span>ANDAR</span>
                        <Carousel
                          showArrows={andarData.length > 3}
                          showThumbs={false}
                          showStatus={false}
                          emulateTouch
                          centerMode={true}
                          showIndicators={false}
                          centerSlidePercentage={33.33} // Show 3 cards at a time
                          selectedItem={initialIndex}
                        >
                          {andarData.map((cardId, index) => (
                            <div key={index}>
                              <img
                                src={findCardImage(cardId)}
                                alt={cardId}
                                style={{
                                  width: "30px", // Adjust width as needed
                                  height: "auto",
                                  marginRight: "3px",
                                }}
                              />
                            </div>
                          ))}
                        </Carousel>
                      </div>

                      <div>
                        <span>BAHAR</span>
                        <Carousel
                          showArrows={andarData.length > 3}
                          showThumbs={false}
                          showStatus={false}
                          emulateTouch
                          centerMode={true}
                          showIndicators={false}
                          centerSlidePercentage={33.33} // Show 3 cards at a time
                          selectedItem={initialIndex}
                        >
                          {baharData.map((cardId, index) => (
                            <div key={index}>
                              <img
                                src={findCardImage(cardId) || cardBlank}
                                alt={cardId}
                                style={{
                                  width: "30px", // Adjust width as needed
                                  height: "auto",
                                  marginRight: "3px",
                                }}
                              />
                            </div>
                          ))}
                        </Carousel>
                      </div>

                      {/* </div> */}
                    </div>
                  </div>

                  <div
                    className="timer"
                    style={{ marginLeft: "550px", marginTop: "20px" }}
                  >
                    <div className="container">
                      <div
                        className="timer_container position-absolute"
                        style={{ right: "40px", bottom: "15px" }}
                      >
                        <button
                          class="btn btn-primary"
                          style={{ cursor: "default" }}
                        >
                          <span>
                            {" "}
                            {reqRes?.data?.data?.t1[0]?.autotime || 0}{" "}
                          </span>
                        </button>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <-------------------------------Back-lay-table----------> */}
              <div className="">
                <div
                  className=" border col-12"
                  style={{ backgroundColor: "#2196F3" }}
                >
                  <div className="row border">
                    <div className="border text-center  col-2">
                      <p className="mt-1 mb-1">Andar</p>
                    </div>
                    <div className=" justify-content-center d-flex flex-wrap mt-1 mb-1 col-10">
                      {/* <div>
                        <img
                          src={a}
                          className="img-fluid mx-1"
                          style={{ width: "30px" }}
                          alt=""
                        />
                        <br />
                        <span className="justify-content-center">0</span>
                      </div> */}
                      {reqRes?.data?.data?.t2
                        ?.slice(0, 13)
                        ?.map((item, index) => (
                          <div className="img-fluid mx-1 mt-1 mb-1" key={index}>
                            <div className="position-relative">
                              {item.gstatus == 0 && (
                                <div className="suspended__2">
                                  <i className="fa fa-lock"></i>
                                </div>
                              )}
                              <img
                                src={imageImports[index]}
                                alt={item.nat}
                                onClick={() => {
                                  handleModal(item.rate, item.nat, index + 1);
                                  setBetIndex(item.sid);
                                }}
                                style={{ width: "30px" }}
                              />
                            </div>{" "}
                            <div className="ubook text-center m-t-5">
                              <b style={{ color: "white" }}>
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === index + 1 && (
                                      <span
                                        className={`${
                                          ele.number == index + 1 &&
                                          ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == index + 1 && ele.amount}
                                      </span>
                                    )
                                )}
                              </b>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div
                  className=" border col-12"
                  style={{ backgroundColor: "#d67f23" }}
                >
                  <div className="row border">
                    <div className="border text-center  col-2">
                      <p className="mt-1 mb-1">Bahar</p>
                    </div>
                    <div className=" justify-content-center d-flex flex-wrap mt-1 mb-1 col-10">
                      {reqRes?.data?.data?.t2
                        ?.slice(13, 26)
                        ?.map((item, index) => (
                          <div className="img-fluid mx-1 mt-1 mb-1" key={index}>
                            <div className="position-relative">
                              {item.gstatus == 0 && (
                                <div className="suspended__2">
                                  <i className="fa fa-lock"></i>
                                </div>
                              )}
                              <img
                                src={imageImports[index]}
                                alt={item.nat}
                                onClick={() => {
                                  handleModal(item.rate, item.nat, index + 14);
                                  setBetIndex(item.sid);
                                }}
                                style={{ width: "30px" }}
                              />
                            </div>{" "}
                            <div className="ubook text-center m-t-5">
                              <b style={{ color: "white" }}>
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === index + 14 && (
                                      <span
                                        className={`${
                                          ele.number == index + 14 &&
                                          ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == index + 14 && ele.amount}
                                      </span>
                                    )
                                )}
                              </b>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="Results-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Last 10 Results</p>
                  </div>
                </div>
                <div className="card rst-card py-1 d-flex">
                  <div className="rst-row d-flex">
                    {results?.data?.data?.map((result, index) => {
                      const item = reqRes?.data?.data?.t2.find(
                        (item) => item.sid === result?.result
                      );
                      console.log("item", item);

                      return (
                        <div
                          onClick={() => {
                            setMidRound(result.mid);
                            CasinoGameResult_API(result.mid);
                            setShowresultModal(true);
                          }}
                          className="rst-btn ms-1"
                          key={index}
                        >
                          {<>R</>}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* <<<<<<<---------My-Bets-table-------------->>>>>>>>>> */}
              <div className="My-Bets-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Open Bets</p>
                  </div>
                </div>
                {HistoryTable}
              </div>
            </div>
          </div>

          <RightSidebar />
          {showModal && (
            <Modal
              id={id}
              name={name}
              betDetails={betDetails}
              setbetDetails={setbetDetails}
              setShowModal={setShowModal}
              realTime={realTime}
              setRealTimeData={setRealTimeData}
            />
          )}
          {showresultModal && <ResultsModal />}
          {/* {showDataModal && <DataModal />} */}
        </div>
      </div>
    </>
  );
}
