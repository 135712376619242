import React from "react";
import { Link } from "react-router-dom";
import a from "../../Media/dashImg/ballbyball.jpg";
import e from "../../Media/dashImg/teen.jpg";
import e20 from "../../Media/dashImg/teen20.jpg";
import e9 from "../../Media/dashImg/teen9.jpg";
import eopen from "../../Media/dashImg/teen8.jpg";
import f from "../../Media/dashImg/poker.jpg";
import g from "../../Media/dashImg/poker20.jpg";
import h from "../../Media/dashImg/poker6.jpg";
import i from "../../Media/dashImg/baccarat.jpg";
import j from "../../Media/dashImg/baccarat2.jpg";
import k from "../../Media/dashImg/dt20.jpg";
import l from "../../Media/dashImg/dt6.jpg";
import m from "../../Media/dashImg/dtl20.jpg";
import n from "../../Media/dashImg/dt202.jpg";
import o from "../../Media/dashImg/card32.jpg";
import p from "../../Media/dashImg/card32eu.jpg";
import q from "../../Media/dashImg/ab20.jpg";
import r from "../../Media/dashImg/abj.jpg";
import s from "../../Media/dashImg/lucky7.jpg";
import t from "../../Media/dashImg/lucky7eu.jpg";
import v from "../../Media/dashImg/war.jpg";
import x from "../../Media/dashImg/worli2.jpg";
import y from "../../Media/dashImg/aaa.jpg";
import z from "../../Media/dashImg/btable.jpg";
import bb from "../../Media/dashImg/cricketv3.jpg";
import gg from "../../Media/dashImg/race20.jpg";
import ii from "../../Media/dashImg/superover.jpg";
import av from "../../Media/img7.png";
import cg from "../../Media/img6.png";
// import w from "../../Media/dashImg/worli.jpg";
// import aa from "../../Media/dashImg/lottcard.jpg";
// import cc from "../../Media/dashImg/cmatch20.jpg";
// import dd from "../../Media/dashImg/cmeter.jpg";
// import ee from "../../Media/dashImg/teen6.jpg";
// import ff from "../../Media/dashImg/queen.jpg";
// import hh from "../../Media/dashImg/lucky7eu2.jpg";
// import u from "../../Media/dashImg/3cardj.jpg";
// import b from "../../Media/dashImg/teen33.jpg";
// import c from "../../Media/dashImg/sicbo.jpg";
// import iteen from "../../Media/dashImg/teen32.jpg";
// import d from "../../Media/dashImg/roulette1.jpg";
// import jj from "../../Media/dashImg/trap.jpg";
// import kk from "../../Media/dashImg/patti2.jpg";
// import ll from "../../Media/dashImg/teensin.jpg";
// import mm from "../../Media/dashImg/teenmuf.jpg";
// import nn from "../../Media/dashImg/race17.jpg";
// import oo from "../../Media/dashImg/teen20b.jpg";
// import pp from "../../Media/dashImg/trio.jpg";
// import qq from "../../Media/dashImg/notenum.jpg";
// import rr from "../../Media/dashImg/kbc.jpg";
// import ss from "../../Media/dashImg/teen120.jpg";
// import tt from "../../Media/dashImg/teen1.jpg";
// import uu from "../../Media/dashImg/roulette.jpg";
// import vv from "../../Media/dashImg/ab3.jpg";
// import ww from "../../Media/dashImg/aaa2.jpg";
// import xx from "../../Media/dashImg/race2.jpg";
// import yy from "../../Media/dashImg/teen3.jpg";
// import zz from "../../Media/dashImg/dum10.jpg";
// import zzz from "../../Media/dashImg/cmeter1.jpg";

export default function DashImage() {
  return (
    <>
      <div className="bootomcards">
        <div className="casino-list mt-2">
          {/* <div className="casino-list-item">
            <Link to="/ballbyball/20">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={a} alt="img" />
              </div>
              <div className="casino-list-name">Ball by Ball</div>
            </Link>
          </div> */}
          {/* <div className="casino-list-item">
            <Link to="/ssuperover/21">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={ii} alt="img" />
              </div>
              <div className="casino-list-name">Super Over</div>
            </Link>
          </div> */}
          <div className="casino-list-item">
            <Link to="/race20/16">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={gg} alt="img" />
              </div>
              <div className="casino-list-name">Race20</div>
            </Link>
          </div>
          {/* <div className="casino-list-item">
            <Link to="/fivecricket/17">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={bb} alt="img" />
              </div>
              <div className="casino-list-name">5Five Cricket</div>
            </Link>
          </div> */}
          <div className="casino-list-item">
            <Link to="/Andar_Bahar/3">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={r} alt="img" />
              </div>
              <div className="casino-list-name">Andar Bahar 2</div>
            </Link>
          </div>
          <div className="casino-list-item">
            <Link to="/DragonTiger2/19">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={n} alt="img" />
              </div>
              <div className="casino-list-name">20-20 Dragon Tiger 2</div>
            </Link>
          </div>
          <div className="casino-list-item">
            <Link to="/baccarat1/22">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={i} alt="img" />
              </div>
              <div className="casino-list-name">Baccarat</div>
            </Link>
          </div>
          <div className="casino-list-item">
            <Link to="/baccarat2/23">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={j} alt="img" />
              </div>
              <div className="casino-list-name">Baccarat 2</div>
            </Link>
          </div>
          <div className="casino-list-item">
            <Link to="/Lucky7A/2">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={s} alt="img" />
              </div>
              <div className="casino-list-name">Lucky 7 - A</div>
            </Link>
          </div>
          <div className="casino-list-item">
            <Link to="/Lucky7B/18">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={t} alt="img" />
              </div>
              <div className="casino-list-name">Lucky 7 - B</div>
            </Link>
          </div>
          <div className="casino-list-item">
            <Link to="/Casinowar/24">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={v} alt="img" />
              </div>
              <div className="casino-list-name">Casino War</div>
            </Link>
          </div>
          <div className="casino-list-item">
            <Link to="/twentyDTL/25">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={m} alt="img" />
              </div>
              <div className="casino-list-name">20-20 D T L</div>
            </Link>
          </div>
          <div className="casino-list-item">
            <Link to="/teenpattiOneday/11">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={e} alt="img" />
              </div>
              <div className="casino-list-name">Teenpatti 1-day</div>
            </Link>
          </div>
          <div className="casino-list-item">
            <Link to="/TeenPatti/4">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={e20} alt="img" />
              </div>
              <div className="casino-list-name">20-20 Teenpatti</div>
            </Link>
          </div>
          <div className="casino-list-item">
            <Link to="/teenpattitest/12">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={e9} alt="img" />
              </div>
              <div className="casino-list-name">Teenpatti Test</div>
            </Link>
          </div>
          <div className="casino-list-item">
            <Link to="/teenpattiOpen/27">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={eopen} alt="img" />
              </div>
              <div className="casino-list-name">Teenpatti Open</div>
            </Link>
          </div>
          <div className="casino-list-item">
            <Link to="/oneDpoker/13">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={f} alt="img" />
              </div>
              <div className="casino-list-name">Poker 1-Day</div>
            </Link>
          </div>
          <div className="casino-list-item">
            <Link to="/twentypoker/6">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={g} alt="img" />
              </div>
              <div className="casino-list-name">20-20 Poker</div>
            </Link>
          </div>
          <div className="casino-list-item">
            <Link to="/sixpoker/26">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={h} alt="img" />
              </div>
              <div className="casino-list-name">Poker 6 Players</div>
            </Link>
          </div>
          <div className="casino-list-item">
            <Link to="/32Cards/1">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={o} alt="img" />
              </div>
              <div className="casino-list-name">32 Cards A</div>
            </Link>
          </div>
          <div className="casino-list-item">
            <Link to="/card32B/15">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={p} alt="img" />
              </div>
              <div className="casino-list-name">32 Cards B</div>
            </Link>
          </div>
          <div className="casino-list-item">
            <Link to="/DragonTiger/5">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={k} alt="img" />
              </div>
              <div className="casino-list-name">20-20 Dragon Tiger</div>
            </Link>
          </div>
          <div className="casino-list-item">
            <Link to="/OneDayDTiger/7">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={l} alt="img" />
              </div>
              <div className="casino-list-name">1 Day Dragon Tiger</div>
            </Link>
          </div>
          <div className="casino-list-item">
            <Link to="/amarakbarant/9">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={y} alt="img" />
              </div>
              <div className="casino-list-name">Amar Akbar Anthony</div>
            </Link>
          </div>
          <div className="casino-list-item">
            <Link to="/Bollywoodcasino/8">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={z} alt="img" />
              </div>
              <div className="casino-list-name">Bollywood Casino</div>
            </Link>
          </div>
          <div className="casino-list-item">
            <Link to="/instantworli/10">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={x} alt="img" />
              </div>
              <div className="casino-list-name">Instant Worli</div>
            </Link>
          </div>

          <div className="casino-list-item">
            <Link to="/andarbaharnew/14">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={q} alt="img" />
              </div>
              <div className="casino-list-name">Andar Bahar</div>
            </Link>
          </div>

          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={b} alt="img" />
              </div>
              <div className="casino-list-name">Instant Teenpatti 3.0</div>
            </Link>
          </div> */}
          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={c} alt="img" />
              </div>
              <div className="casino-list-name">Sic Bo</div>
            </Link>
          </div> */}

          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={iteen} alt="img" />
              </div>
              <div className="casino-list-name">Instant Teenpatti 2.0</div>
            </Link>
          </div> */}
          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={d} alt="img" />
              </div>
              <div className="casino-list-name">Roulette</div>
            </Link>
          </div> */}

          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={u} alt="img" />
              </div>
              <div className="casino-list-name">3 Cards Judgement</div>
            </Link>
          </div> */}

          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={w} alt="img" />
              </div>
              <div className="casino-list-name">Worli Matka</div>
            </Link>
          </div> */}

          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={aa} alt="img" />
              </div>
              <div className="casino-list-name">Lottery</div>
            </Link>
          </div> */}

          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={cc} alt="img" />
              </div>
              <div className="casino-list-name">Cricket Match 20-20</div>
            </Link>
          </div> */}
          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={dd} alt="img" />
              </div>
              <div className="casino-list-name">Casino Meter</div>
            </Link>
          </div> */}
          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={ee} alt="img" />
              </div>
              <div className="casino-list-name">Teenpatti - 2.0</div>
            </Link>
          </div> */}
          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={ff} alt="img" />
              </div>
              <div className="casino-list-name">Queen</div>
            </Link>
          </div> */}

          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={hh} alt="img" />
              </div>
              <div className="casino-list-name">Lucky 7 - C</div>
            </Link>
          </div> */}

          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={jj} alt="img" />
              </div>
              <div className="casino-list-name">The Trap</div>
            </Link>
          </div> */}
          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={kk} alt="img" />
              </div>
              <div className="casino-list-name">2 Cards Teenpatti</div>
            </Link>
          </div> */}
          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={ll} alt="img" />
              </div>
              <div className="casino-list-name">29Card Baccarat</div>
            </Link>
          </div> */}
          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={mm} alt="img" />
              </div>
              <div className="casino-list-name">Muflis Teenpatti</div>
            </Link>
          </div> */}
          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={nn} alt="img" />
              </div>
              <div className="casino-list-name">Race To 17</div>
            </Link>
          </div> */}
          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={oo} alt="img" />
              </div>
              <div className="casino-list-name">20-20 Teenpatti B</div>
            </Link>
          </div> */}
          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={pp} alt="img" />
              </div>
              <div className="casino-list-name">Trio</div>
            </Link>
          </div> */}
          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={qq} alt="img" />
              </div>
              <div className="casino-list-name">Note Number</div>
            </Link>
          </div> */}
          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={rr} alt="img" />
              </div>
              <div className="casino-list-name">K.B.C</div>
            </Link>
          </div> */}
          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={ss} alt="img" />
              </div>
              <div className="casino-list-name">1 CARD 20-20</div>
            </Link>
          </div> */}
          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={tt} alt="img" />
              </div>
              <div className="casino-list-name">1 CARD ONE-DAY</div>
            </Link>
          </div> */}
          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={uu} alt="img" />
              </div>
              <div className="casino-list-name">Roulette</div>
            </Link>
            </div> */}
          {/* <div className="casino-list-item">
              <Link to="">
                <div className="casino-list-item-banner">
                  <img className="w-100 h-100" src={vv} alt="img" />
                </div>
                <div className="casino-list-name">ANDAR BAHAR 50 cards</div>
              </Link>
            </div> */}
          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={ww} alt="img" />
              </div>
              <div className="casino-list-name">Amar Akbar Anthony 2</div>
            </Link>
          </div> */}
          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={xx} alt="img" />
              </div>
              <div className="casino-list-name">Race to 2nd</div>
            </Link>
          </div> */}
          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={yy} alt="img" />
              </div>
              <div className="casino-list-name">Instant Teenpatti</div>
            </Link>
          </div> */}
          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={zz} alt="img" />
              </div>
              <div className="casino-list-name">Dus ka Dum</div>
            </Link>
          </div> */}
          {/* <div className="casino-list-item">
            <Link to="">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={zzz} alt="img" />
              </div>
              <div className="casino-list-name">1 Card Meter</div>
            </Link>
          </div> */}
          <div className="casino-list-item">
            <Link to="/Aviator">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={av} alt="img" />
              </div>
              <div className="casino-list-name">Aviator</div>
            </Link>
          </div>
          <div className="casino-list-item">
            <Link to="/ColorGame">
              <div className="casino-list-item-banner">
                <img className="w-100 h-100" src={cg} alt="img" />
              </div>
              <div className="casino-list-name">Color Game</div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
