import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API } from "../../API/API";
import "../Registration/WelcomePage.css";
import logo1 from "../../Media/cricketlogo.png";

export default function WelcomePage() {
  const { uid } = useParams();
  const name = sessionStorage.getItem("Name");
  const [password, setPassword] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [getAllData, setGetAllData] = useState({
    uid: "User Id",
    email: "Email Address",
    mobile: "mobile",
    user_id: "user_id",
    password: "password",
  });

  const Welcome_API = async () => {
    try {
      setSpinner(true);
      let res = await API.get(`welcomeLetter?uid=${uid}`);
      
      console.log("WelcomeLetter-->", res);


      res = res.data.data[0][0];
      if (res !== undefined) {
        setGetAllData(res);
        setBtnDisable(false);
      } else {
        Welcome_API();
      }
    } catch (error) {
      console.error("WelcomePage API Error:", error);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    Welcome_API();
    const storedPassword = sessionStorage.getItem("Password");
    setPassword(storedPassword || "*****");
  }, []);

  return (
    <>
      <div className="background-img" style={{overflowY: "scroll"}}>
        <div className="d-flex justify-content-center align-items-center">
          <div className="card bg-dark col-12 col-md-6 mt-sm-5 py-5 py-sm-0" >
            <div className="d-flex justify-content-center mt-4">
              <img className="img-fluid img-div" src={logo1} alt="a" />
            </div>
            <div className="text-center ">
                  <h2 style={{ textAlign: "center", color: "red" }}>
                    Welcome to Game Coin
                  </h2>
                  <h3 style={{ textAlign: "center", color: "#ff8400" }}>
                    WELCOME LETTER
                  </h3>
                  <h4 style={{ color: "white" }} >
                    TO Mr./ Mrs./ Miss,{" "}
                    <span style={{ color: "red" }}>{name}</span>{" "}
                  </h4>
                  <p style={{ color: "white" }}>
                    THANK YOU FOR THE REGISTRATION WITH Game Coin. YOUR
                    CORDIAL ASSOCIATION IS WELCOME IN THE BUSINESS FAMILY. WISH
                    YOU A BRIGHT FUTURE FOR GROWTH.
                  </p>
                  <br />
                  <h5 style={{ color: "red" }}>Name: {name} </h5>
                  <h5 style={{ color: "red" }}>User ID: {getAllData.uid}</h5>
                  {/* <h5 style={{ color: "red" }}>
                    Login ID: {getAllData.user_id}
                  </h5> */}
                  <h5 style={{ color: "red" }}>
                    Email Id: {getAllData.email}{" "}
                  </h5>
                  <h5 style={{ color: "red" }}>
                    Mobile No.: {getAllData.mobile}{" "}
                  </h5>
                  <br />
                  <p
                    style={{
                      width: "100%",
                      float: "left",
                      textAlign: "center",
                    }}
                  >
                    {spinner ? (
                      <span>Loading...</span>
                    ) : (
                      <Link
                        to="/login"
                        type="button"
                        // disabled={btnDisable}
                        style={{
                          color: "#0d0d0d",
                          textDecoration: "none",
                          backgroundColor: "#fedc00",
                          borderRadius: 10,
                          padding: "6px 14px",
                        }}
                      >
                        Go To Login
                      </Link>
                    )}
                  </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
