import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo1 from "../../Media/cricketlogo.png";
import "./Registration.css";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { API } from "../../API/API";
import { toast } from "react-toastify";

export default function Registration() {
  let history = useNavigate();
  const [checkbox, setcheckbox] = useState(false);
  const [SponsorId, setSponsorId] = useState(224466);
  const [SponsorName, setSponsorName] = useState("Game Coin");
  const [f_name, setf_name] = useState(null);
  const [Password, setPassword] = useState(null);
  const [ConfirmPassword, setConfirmPassword] = useState(null);
  const [MobileNo, setMobileNo] = useState(null);
  const [EmailId, setEmailId] = useState(null);
  const [EmailIdError, setEmailIdError] = useState(null);
  const [Position, setPosition] = useState(0);

  const GetSponsorDetails = async (e) => {
    let { value } = e.target;
    const newValue = value.replace(/[^0-9]/gi, "").substring(0, 8);
    if (newValue === "") {
      setSponsorId("");
      setSponsorName("");
    } else {
      setSponsorId(newValue);
      let res = await API.post(`/check_sponsorid`, { uid: newValue });

      if (res.data.data.result === "Sponsor Id doesnot exists !!") {
        setSponsorName("Wrong sponser id");
      } else {
        // let { f_name } = res.data.data[0];
        setSponsorName(res.data.data.result);
      }
    }
  };

  const checkUserName = async (e) => {
    let { value } = e.target;

    const newValue = value.replace(/[^A-Z a-z]/g, "");

    if (newValue === "") {
      setf_name("");
    } else {
      setf_name(newValue);
    }
  };

  const checkEmailId = async (e) => {
    let { value } = e.target;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailId(value);
    if (!emailRegex.test(value)) {
      setEmailIdError("Please enter a valid email address.");
    } else {
      setEmailIdError("");
    }
  };

  const Registration = async (e) => {
    // setspin  nerload(true);

    try {
      if (Password !== ConfirmPassword) {
        toast.error("Password Not Mathced!!!");
        return;
      }
      let resSponsor = await API.post(`/check_sponsorid`, { uid: SponsorId });

      if (resSponsor.data.data.result === "Sponsor Id doesnot exists !!") {
        toast.error("Wrong sponser id!!!");
        return;
      }

      let res = await API.post("/User_Registration", {
        sid: SponsorId,
        sponser_id: "",
        f_name: f_name,
        email: EmailId,
        psw: Password,
        mob: MobileNo,
        Bookie_Id: "0",
        RegBy: "User",
        position: Position,
      });
      console.log("RegistrationResponse-->", res);

      if (res.data.data.result === "Successfully Registered") {
        sessionStorage.setItem("Name", f_name);
        sessionStorage.setItem("Password", Password);
        toast.success(`Successful`);
        let uid = res.data.data.uid_output;
        console.log("resuid", uid);
        history(`/welcomepage/${uid}`);

        // history(`/WelcomePage?userid=${uid}`);
      } else {
        toast.error(`${res.data.data.result}`);
        // setspinnerload(false);
      }
      // setspinnerload(false);
    } catch (error) {
      console.log("API ERROR", error);
    }
  };

  // const emailVerification = async (Id) => {
  //   try {
  //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //     console.log("Email_check", getEmail);
  //     if (!emailRegex.test(getEmail)) {
  //       toast.error("Please enter a valid email address.");
  //     } else {
  //       setspinner(true);
  //       let res = await API.post("/emailVerification", {
  //         email: getEmail,
  //       });
  //       if (res.data.data === "Successfull") {
  //         Id === "Resend"
  //           ? toast.success(`OTP send Successfull on this ${getEmail}  `)
  //           : toast.success(`OTP Resend Successfull on this ${getEmail}  `);
  //       }
  //       setspinner(false);
  //     }

  //     //   console.log("res", res);
  //   } catch (error) {
  //     console.log(error);
  //     setspinner(false);
  //   }
  // };

  const ReferralAddress = async () => {
    try {
      let URL = window.location.href;
      let URL_ID = new URLSearchParams(window.location.search).get("userid");

      if (URL.includes("userid")) {
        // setcheckreffarl(true);
        setSponsorId(URL_ID);
        let res = await API.post(`/check_sponsorid`, { uid: URL_ID });

        if (res.data.data.result === "Sponsor Id doesnot exists !!") {
          setSponsorName("Wrong sponser id");
        } else {
          // let { f_name } = res.data.data[0];
          setSponsorName(res.data.data.result);
        }
      } else {
      }
    } catch (e) {
      console.log("Erroe Whille Referral Fuction Call", e);
    }
  };

  useEffect(() => {
    // ReferralAddress();
  }, []);

  return (
    <>
      <div className="backgroundimg">
        <div className="d-flex justify-content-center align-items-center">
          <div className="registration-card col-12 col-md-7 mt-lg-3 mt-5 h-100">
            <div className="d-flex justify-content-center mt-4">
              <img className="img-fluid img-div" src={logo1} alt="a" />
            </div>

            <div className="row mx-1">
              <div className="col-sm-6 ">
                <div className="col-12  mx-1">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Sponser ID
                  </label>
                  <input
                    type="text"
                    className=" registration-input p-1 px-2"
                    id="exampleInputEmail1"
                    placeholder="Enter Sponser ID"
                    aria-describedby="emailHelp"
                    value={SponsorId}
                    maxLength={10}
                    readOnly="true"
                    onChange={(e) => {
                      GetSponsorDetails(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="col-12 mx-1">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Sponsor Name
                  </label>
                  <input
                    type="text"
                    className="registration-input p-1 px-2"
                    id="exampleInputEmail1"
                    placeholder="Sponsor Name"
                    aria-describedby="emailHelp"
                    readOnly="true"
                    value={SponsorName}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="col-12 mx-1">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Position
                  </label>
                  <select
                    onChange={(e) => {
                      setPosition(e.target.value);
                    }}
                    className="registration-input p-1 px-2"
                    value={Position}
                  >
                    <option value={"0"}>Select Position</option>
                    <option value={"1"}>Left</option>
                    <option value={"2"}>Right</option>
                  </select>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="col-12 mx-1">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    User Name
                  </label>
                  <input
                    type="text"
                    className=" registration-input p-1 px-2"
                    id="exampleInputEmail1"
                    placeholder="Enter UserName"
                    aria-describedby="emailHelp"
                    maxLength={50}
                    value={f_name}
                    onChange={(e) => {
                      checkUserName(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-12 mx-1">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      E-mail ID
                    </label>
                    <input
                      type="text"
                      className=" registration-input p-1 px-2"
                      id="exampleInputEmail1"
                      placeholder="Enter Email ID"
                      aria-describedby="emailHelp"
                      maxLength={100}
                      onChange={(e) => {
                        checkEmailId(e);
                      }}
                      value={EmailId}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-12 mx-1">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Mobile No.
                    </label>
                    <input
                      type="text"
                      className="registration-input p-1 px-2"
                      placeholder="Enter Mobile No"
                      maxLength={15}
                      onChange={(e) => {
                        setMobileNo(e.target.value);
                      }}
                      value={MobileNo}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mx-1">
              <div className="col-sm-6">
                <div className="col-12 mx-1">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Enter Password
                  </label>
                  <input
                    type="password"
                    className=" registration-input p-1 px-2"
                    placeholder="Enter your password"
                    id="exampleInputPassword1"
                    maxLength={20}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    value={Password}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="col-12 mx-1">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className=" registration-input p-1 px-2"
                    placeholder="Confirm your password"
                    id="exampleInputPassword1"
                    maxLength={20}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    value={ConfirmPassword}
                  />
                </div>
              </div>
            </div>
            <div>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox color="success" />}
                  checked={checkbox}
                  onChange={(e) => setcheckbox(e.target.checked)}
                  className="text-white " style={{fontSize:"14px"}}
                  required
                  label="I Agree Your Terms and Conditions"
                />
              </FormGroup>
            </div>
            <div className="d-flex justify-content-center">
              <button
                className="btn login-btn mt-2"
                onClick={(e) => {
                  Registration(e);
                }}
              >
                Registration
              </button>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="bth d-flex justify-content-center mt-2">
                  Back to &nbsp;{" "}
                  <span>
                    <Link className="nav-link" to="/">
                      {" "}
                      Home
                    </Link>{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="bth d-flex justify-content-center mt-2">
                  Already have an Account &nbsp;{" "}
                  <span>
                    <Link className="nav-link" to="/login">
                      {" "}
                      Sign In
                    </Link>{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
