import React, { useEffect, useState } from "react";
import { MdScreenshotMonitor } from "react-icons/md";
import { MdChecklist } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { json, useNavigate, useParams } from "react-router-dom";
//import { useNavigate, useParams } from "react-router-dom";
import { fetchDashbetHistory } from "../../Redux/Slices/dashbetHistorySlice";
import { API } from "../../API/API";
import axios from "axios";

const LiveTvSection = () => {
  const { id } = useParams();

  const token = sessionStorage.getItem("token");
  const userId = sessionStorage.getItem("userData");
  const { dashbetHistory } = useSelector((state) => state.dashbetHistory);
  const [chID, setchID] = useState("");
  const [active, setActive] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://betexch-api.nakshtech.info/getGameChannelID?matchid=" + id
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        // setData(jsonData.data);
        const chID = jsonData.data;
        setchID(chID);
        console.log("jsonData", jsonData);
        console.log("id", id);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div className="px-1 d-lg-block d-none">
      <div className="TV-outer-div px-3 py-2">
        <div className={active ? "live-tv-card" : "live-tv-card-active"}>
          <div className="d-flex justify-content-between tv-card pb-0 mb-0 p-1">
            <div className="d-flex mt-1 ms-1 ">
              <MdScreenshotMonitor className="tv-icon" />
              <p className="ms-2 mb-2">LIVE TV</p>
            </div>

            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                value={active}
                onChange={() => setActive(!active)}
              />
            </div>
          </div>
          {active && (
            <iframe
              allowFullScreen={true}
              style={{
                width: "100%",
                height: "210px",
                border: "none",
                opacity: "1",
                visibility: "visible",
                color: "white ",
              }}
              id="frame_video_2"
              src={`https://video.proexch.in/tv/static?chid=${chID}`}
            ></iframe>
          )}
        </div>
        <div className="matched-bets mt-3">
          <div className="d-flex justify-content-between tv-card mb-0 pb-0 p-1">
            {/* <div className="d-flex mt-1 ms-1 mb-0 "> */}

            <div className="w-100" style={{ height: "200px" }}>
              <div
                className="table-responsive maintableD "
                style={{ height: "198px" }}
              >
                <table className="table w-100">
                  <thead className="w-100">
                    <tr>
                      <th style={{ width: "45%" }} scope="col">
                        Matched Bet
                      </th>
                      <th style={{ width: "15%" }} scope="col">
                        Odds
                      </th>
                      <th style={{ width: "15%" }} scope="col">
                        Stake
                      </th>
                      <th style={{ width: "15%" }} scope="col">
                        Profit
                      </th>
                    </tr>
                  </thead>
                  {dashbetHistory.length > 0 ? (
                    <tbody>
                      {dashbetHistory?.map((item) => {
                        return item.back > 0 ? (
                          <tr
                            key={item.id}
                            style={{ backgroundColor: "#0b9cf1" }}
                          >
                            <td style={{ width: "45%" }}>{item.team}</td>
                            <td style={{ width: "15%" }}>
                              {item.lay > 0 ? (
                                <div className="">{item.lay}</div>
                              ) : (
                                <div className="">{item.back}</div>
                              )}
                            </td>
                            <td style={{ width: "15%" }}>{item.stake}</td>
                            <td style={{ width: "15%" }}>{item.profit || 0}</td>
                          </tr>
                        ) : (
                          <tr
                            key={item.id}
                            style={{ backgroundColor: "#f37a13" }}
                          >
                            <td style={{ width: "45%" }}>{item.team}</td>
                            <td style={{ width: "15%" }}>
                              {item.lay > 0 ? (
                                <div className="">{item.lay}</div>
                              ) : (
                                <div className="">{item.back}</div>
                              )}
                            </td>
                            <td style={{ width: "15%" }}>{item.stake}</td>
                            <td style={{ width: "15%" }}>{item.profit || 0}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr className="text-white">No Bet Place !</tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
          {/* <div className="bet-matches ms-2 p-2">AUSTRALIA VS NEWZEELAND</div>
          <div className="no-bets ms-1 p-2">NO BETS</div> */}
        </div>
      </div>
    </div>
  );
};

export default LiveTvSection;
