import React, { useEffect, useState } from "react";
import ScoreFootball from "../Sports Football/ScoreFootball";
import TennisOdds from "./TennisOdds";
import LiveTvSection from "../SportNew/LiveTvSection";
import ScoreCard from "../SportNew/ScoreCard";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LastBetDetails } from "../../Redux/Slices/LastbetSlice";
import { fetchDashbetHistory } from "../../Redux/Slices/dashbetHistorySlice";

const TennisSport = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");

  useEffect(() => {
    // dispatch(fetchuserBalance({ id: uid, token }));
    dispatch(LastBetDetails({ userId: uid, id: id, token }));
    dispatch(fetchDashbetHistory({ userId: uid, token, id }));
  }, []);
// export default function TennisSport() {
  return (
    // <div className="col-lg-10  pt-5 pt-3 ">
    <div className="col-lg-10 com-h mt-5 pt-3">
      <div className="row px-1">
        <div className="col-lg-8  px-2 score-card">
          <ScoreCard />
          <TennisOdds />
        </div>
        <div className="col-4">
          <LiveTvSection />
        </div>
      </div>
    </div>
  );
}

export default TennisSport;