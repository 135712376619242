import React, { useState, useEffect } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import a from "../../Media/10SS.png";
import b from "../../Media/3SS.png";
import { MdCancel } from "react-icons/md";
import { FaTrophy } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { API } from "../../API/API";
import Modal from "../TeenPatti/Modal";
import { fetchThirtyTwoCardStack } from "../../Redux/Slices/thirtyTwoCardGameSlice";
//import { card52War } from "../../Utility/52CardImages.js";
import { card52War } from "../../Utility/52CardImages.js";
import cardBlank from "../../Media/DTL2020/backcard.webp";

export default function TeenPattiOpen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");

  const [showresultModal, setShowresultModal] = useState(false);
  const [showDataModal, setShowdataModal] = useState(false);

  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [rulesModal, setRulesModal] = useState(false);
  const [realTime, setRealTimeData] = useState(false);
  const [betIndex, setBetIndex] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [betDetails, setbetDetails] = useState({});
  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const [history, setHistory] = useState([]);
  const [trophy, setTrophy] = useState("");
  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  const SOCKET_URL = "https://gamecoin-socket.nakshtech.info/";

  // console.log("casinoGameResult", casinoGameResult);

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      // console.log("CasinoGameResult dragon:", response.data.data[0]);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"
        ></div>
        <div className="results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound}</div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
              }}
            />
          </div>
          <div>
            <div className="container d-flex">
              <div className="row">
                <div className="d-flex justify-content-center mt-2">
                  <div className="player-a-div ">
                    <div className="text-center">Player 1</div>
                    <div className="d-flex">
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 0 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 9 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 18 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <div className="player-a-div  ">
                    <div className="d-flex justify-content-between">
                      <div className="text-center">Player 2</div>
                      <FaTrophy className="trophy-icon" />
                    </div>

                    <div className="d-flex">
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 1 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 10 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 19 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <div className="player-a-div ">
                    <div className="text-center">Player 3</div>
                    <div className="d-flex">
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 2 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 11 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 20 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <div className="player-a-div ">
                    <div className="text-center">Player 4</div>
                    <div className="d-flex">
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 3 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 12 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 21 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="d-flex justify-content-center mt-2">
                  <div className="player-a-div">
                    <div className="d-flex justify-content-between">
                      <div className="text-center">Dealer</div>
                      <FaTrophy className="trophy-icon" />
                    </div>

                    <div className="d-flex">
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 8 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 17 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 26 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                      {/* <div className="gamecard-div mx-1">
                        <img src={b} className="img-fluid" alt="a" />
                      </div>
                      <div className="gamecard-div mx-1">
                        <img src={a} className="img-fluid" alt="a" />
                      </div>
                      <div className="gamecard-div mx-1">
                        <img src={b} className="img-fluid" alt="a" />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="d-flex justify-content-center mt-2">
                  <div className="player-a-div ">
                    <div className="text-center">Player 5</div>
                    <div className="d-flex">
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 4 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 13 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 22 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <div className="player-a-div  ">
                    <div className="d-flex justify-content-between">
                      <div className="text-center">Player 6</div>
                      <FaTrophy className="trophy-icon" />
                    </div>

                    <div className="d-flex">
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 5 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 14 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 23 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <div className="player-a-div ">
                    <div className="text-center">Player 7</div>
                    <div className="d-flex">
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 6 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 15 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 24 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <div className="player-a-div ">
                    <div className="text-center">Player 8</div>
                    <div className="d-flex">
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 7 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 16 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                      {casinoGameResult?.map(
                        (item, index) =>
                          index === 25 && (
                            <div className="gamecard-div mx-1" key={index}>
                              <img
                                src={item.image}
                                className="img-fluid"
                                alt={`Player A Card ${index}`}
                              />
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const parseCardData = (cardData) => {
    return cardData.split(",").map((card) => {
      const cardName = card.trim().split("#")[0]; // Extract card name and trim any whitespace
      return cardName;
    });
  };

  const arrayData = parseCardData(reqRes?.data?.data?.t1[0]?.cards ?? "");
  console.log("array", arrayData);

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {/* {loading ? (
              <tr>
                <td colSpan="6">Loading ...</td>
              </tr>
            ) : */}
            {history.length > 0 ? (
              history.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  async function ResultScheduler_API() {
    try {
      let res = await API.post(
        "/Schulder_7CareGameLive",
        {
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  const handleModal = (value, nat, no) => {
    console.log("handleModal", value, nat, no);

    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nat: nat,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);
    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id]);

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);
    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      //  console.log("GetCasinoGameSocket",data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));
        //  console.log("autotime", parsedData[0].reqResponse.data.data.t1[0].autotime);

        console.log("reqResponse", parsedData[0].reqResponse);
        // console.log("resultUrlResponse", parsedData[0].resultUrlResponse);

        setResults(parsedData[0].resultUrlResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  function checkValid() {
    // console.log("showModal",showModal,realTime);
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.sid === betIndex
      );

      console.log("filter", filteredData, betDetails);
      console.log("filteredData.length", filteredData.length);

      filteredData[0]?.gstatus == "1" &&
      betDetails?.nat === filteredData[0]?.nat &&
      betDetails?.multipler === filteredData[0]?.rate
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  // const findCardImage = (cardId) => {
  //   const card = card52War.find((card) => card.id === cardId);
  //   return card ? card.src : null;
  // };

  const findCardImage = (cardId) => {
    const card = card52War.find((card) => card.id === cardId);
    return card ? card.src : null;
  };

  return (
    <>
      <div className="col-lg-10 ">
        <div className="row home mainhomeDev">
          <div className="col-lg-8 p-1">
            <div className="teenpatti-game-div pt-2 p-2">
              <div className="teenpatti-game-h d-flex justify-content-between  ">
                <div className=" px-2">
                  <p className=" mt-1 ">OPEN TEENPATTI</p>
                </div>
                <div>
                  <p className="mt-1 px-2">
                    Round ID: {reqRes?.data?.data?.t1[0]?.mid || 0.0} | Left
                    Time: {reqRes?.data?.data?.t1[0]?.autotime || 0} | Rules
                  </p>
                </div>
              </div>
              <div className="card  teenpatti-game-card p-1 mt-1">
                <div className="row">
                  <div className="col-10">
                    <div className="" style={{ height: "170%", width: "120%" }}>
                      <iframe
                        height="100%"
                        width="100%"
                        src={`https://battlemoney.live/iframe/${id}`}
                        title=""
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-end">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="timer ">
                    <div
                      className="container"
                      style={{
                        color: "white",
                        marginLeft: "-1px",
                        marginTop: "-150px",
                      }}
                    >
                      {/* <div className="timer_container position-absolute"  > */}
                      <span>DEALER</span>
                      <div>
                        {[8, 17, 26].map((key, index) => {
                          const cardId = arrayData[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId) || cardBlank}
                                alt={cardId}
                                style={{
                                  width: "30px",
                                  height: "auto",
                                  marginLeft: "3px",
                                }}
                              />
                            </span>
                          ) : null;
                        })}
                      </div>
                    </div>
                  </div>
                  <div
                    className="timer "
                    style={{ marginLeft: "550px", marginTop: "20px" }}
                  >
                    <div className="container">
                    <div
                      className="timer_container position-absolute"
                      style={{ right: "36px", bottom: "15px" }}
                    >
                      <button
                        class="btn btn-secondary"
                        style={{ cursor: "default", background: "#6f42c1" }}
                      >
                        <span>
                          {" "}
                          {reqRes?.data?.data?.t1[0]?.autotime || 0}{" "}
                        </span>
                      </button>{" "}
                    </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <-------------------------------Back-lay-table----------> */}
              <div>
                <div className="table-h-div row">
                  <div className="col-2 d-flex align-items-center ps-2" />
                  <div className="col-5 d-flex align-items-center justify-content-center px-2">
                    Odds
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2">
                    Pair Plus
                  </div>
                  {/* <div className="col-3 d-flex align-items-center justify-content-center px-2">
                    Total
                  </div> */}
                </div>
                <div className="card-table-inner-row row">
                  <div className="col-2 d-flex align-items-center ps-2">
                  <b>  {reqRes?.data?.data?.t2[0]?.nat} </b>

                    {[0, 9, 18].map((key, index) => {
                      const cardId = arrayData[key];
                      return cardId ? (
                        <span key={index}>
                          <img
                            src={findCardImage(cardId)}
                            style={{
                              width: "15px",
                              height: "auto",
                              marginLeft: "3px",
                            }}
                          />
                        </span>
                      ) : null;
                    })}
                  </div>

                  <div className="col-5 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[0]?.gstatus != "1" ? (
                      <button className="back-btn suspended-box">
                        <span>{reqRes?.data?.data?.t2[0]?.rate} </span>
                      </button>
                    ) : (
                      <button
                        className="back-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t2[0]?.rate,
                            reqRes?.data?.data?.t2[0]?.nat,
                            1
                          );
                          setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                        }}
                      >
                        <span>{reqRes?.data?.data?.t2[0]?.rate} </span>
                        <p className="text-center text-success">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 1 && (
                                <span
                                  className={`${
                                    ele.number == 1 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 1 && ele.amount}
                                </span>
                              )
                          )}
                        </p>
                      </button>
                    )}

                    {/* </button> */}
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[8]?.gstatus != "1" ? (
                      <button className="back-btn suspended-box">
                        <span>{reqRes?.data?.data?.t2[8]?.rate} </span>
                      </button>
                    ) : (
                      <button
                        className="back-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t2[8]?.rate,
                            reqRes?.data?.data?.t2[8]?.nat,
                            2
                          );
                          setBetIndex(reqRes?.data?.data?.t2[8]?.sid);
                        }} 
                      >
                        <span>{reqRes?.data?.data?.t2[8]?.nat}</span>
                        <p className="text-center text-success">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 2 && (
                                <span
                                  className={`${
                                    ele.number == 2 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 2 && ele.amount}
                                </span>
                              )
                          )}
                        </p>
                      </button>
                    )}

                    {/* </button> */}
                  </div>
                </div>
                <div className="card-table-inner-row row">
                  <div className="col-2 d-flex align-items-center ps-2">
                 <b>  {reqRes?.data?.data?.t2[1]?.nat}{" "} </b> 
                    {[1, 10, 19].map((key, index) => {
                      const cardId = arrayData[key];
                      return cardId ? (
                        <span key={index}>
                          <img
                            src={findCardImage(cardId)}
                            style={{
                              width: "15px",
                              height: "auto",
                              marginLeft: "3px",
                            }}
                          />
                        </span>
                      ) : null;
                    })}
                  </div>
                  <div className="col-5 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[1]?.gstatus != "1" ? (
                      <button className="back-btn suspended-box">
                        <span>{reqRes?.data?.data?.t2[1]?.rate} </span>
                      </button>
                    ) : (
                      <button
                        className="back-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t2[1]?.rate,
                            reqRes?.data?.data?.t2[1]?.nat,
                            4
                          );
                          setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                        }}
                      >
                        <span>{reqRes?.data?.data?.t2[1]?.rate} </span>
                        <p className="text-center text-success">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 4 && (
                                <span
                                  className={`${
                                    ele.number == 4 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 4 && ele.amount}
                                </span>
                              )
                          )}
                        </p>
                      </button>
                    )}
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[9]?.gstatus != "1" ? (
                      <button className="back-btn suspended-box">
                        <span>{reqRes?.data?.data?.t2[9]?.rate} </span>
                      </button>
                    ) : (
                      <button
                        className="back-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t2[9]?.rate,
                            reqRes?.data?.data?.t2[9]?.nat,
                            5
                          );
                          setBetIndex(reqRes?.data?.data?.t2[9]?.sid);
                        }}
                      >
                        <span>{reqRes?.data?.data?.t2[9]?.nat} </span>
                        <p className="text-center text-success">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 5 && (
                                <span
                                  className={`${
                                    ele.number == 5 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 5 && ele.amount}
                                </span>
                              )
                          )}
                        </p>
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div style={{ borderTop: "2px solid white" }}>
                <div className="table-h-div row">
                  <div className="col-2 d-flex align-items-center ps-2" />

                  <div className="col-2 d-flex align-items-center justify-content-center " />
                </div>
                <div className="card-table-inner-row row">
                  <div className="col-2 d-flex align-items-center ps-2">
                  <b> Player 3{" "} </b> 
                    {[2, 11, 20].map((key, index) => {
                      const cardId = arrayData[key];
                      return cardId ? (
                        <span key={index}>
                          <img
                            src={findCardImage(cardId)}
                            style={{
                              width: "15px",
                              height: "auto",
                              marginLeft: "3px",
                            }}
                          />
                        </span>
                      ) : null;
                    })}
                  </div>
                  <div className="col-5 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[2]?.gstatus != "1" ? (
                      <button className="back-btn suspended-box">
                        <span>{reqRes?.data?.data?.t2[2]?.rate} </span>
                      </button>
                    ) : (
                      <button
                        className="back-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t2[2]?.rate,
                            reqRes?.data?.data?.t2[2]?.nat,
                            7
                          );
                          setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                        }}
                      >
                        <span>{reqRes?.data?.data?.t2[2]?.rate} </span>
                        <p className="text-center text-success">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 7 && (
                                <span
                                  className={`${
                                    ele.number == 7 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 7 && ele.amount}
                                </span>
                              )
                          )}
                        </p>
                      </button>
                    )}
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[2]?.gstatus != "1" ? (
                      <button className="back-btn suspended-box">
                        <span>{reqRes?.data?.data?.t2[2]?.rate} </span>
                      </button>
                    ) : (
                      <button
                        className="back-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t2[10]?.rate,
                            reqRes?.data?.data?.t2[10]?.nat,
                            8
                          );
                          setBetIndex(reqRes?.data?.data?.t2[10]?.sid);
                        }}
                      >
                        <span>{reqRes?.data?.data?.t2[10]?.nat} </span>
                        <p className="text-center text-success">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 8 && (
                                <span
                                  className={`${
                                    ele.number == 8 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 8 && ele.amount}
                                </span>
                              )
                          )}
                        </p>
                      </button>
                    )}
                  </div>
                </div>
                <div className="card-table-inner-row row">
                  <div className="col-2 d-flex align-items-center ps-2">
                  <b>  Player 4{" "} </b>
                    {[3, 12, 21].map((key, index) => {
                      const cardId = arrayData[key];
                      return cardId ? (
                        <span key={index}>
                          <img
                            src={findCardImage(cardId)}
                            style={{
                              width: "15px",
                              height: "auto",
                              marginLeft: "3px",
                            }}
                          />
                        </span>
                      ) : null;
                    })}
                  </div>
                  <div className="col-5 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[3]?.gstatus != "1" ? (
                      <button className="back-btn suspended-box">
                        <span>{reqRes?.data?.data?.t2[3]?.rate} </span>
                      </button>
                    ) : (
                      <button
                        className="back-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t2[3]?.rate,
                            reqRes?.data?.data?.t2[3]?.nat,
                            10
                          );
                          setBetIndex(reqRes?.data?.data?.t2[3]?.sid);
                        }}
                      >
                        <span>{reqRes?.data?.data?.t2[3]?.rate} </span>
                        <p className="text-center text-success">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 10 && (
                                <span
                                  className={`${
                                    ele.number == 10 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 10 && ele.amount}
                                </span>
                              )
                          )}
                        </p>
                      </button>
                    )}
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[11]?.gstatus != "1" ? (
                      <button className="back-btn suspended-box">
                        <span>{reqRes?.data?.data?.t2[11]?.rate} </span>
                      </button>
                    ) : (
                      <button
                        className="back-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t2[11]?.rate,
                            reqRes?.data?.data?.t2[11]?.nat,
                            11
                          );
                          setBetIndex(reqRes?.data?.data?.t2[11]?.sid);
                        }}
                      >
                        {" "}
                        <span>{reqRes?.data?.data?.t2[11]?.nat} </span>
                        <p className="text-center text-success">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 11 && (
                                <span
                                  className={`${
                                    ele.number == 11 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 11 && ele.amount}
                                </span>
                              )
                          )}
                        </p>
                      </button>
                    )}
                  </div>
                </div>
                <div className="card-table-inner-row row">
                  <div className="col-2 d-flex align-items-center ps-2">
               <b>   Player 5{" "} </b>  
                    {[4, 13, 22].map((key, index) => {
                      const cardId = arrayData[key];
                      return cardId ? (
                        <span key={index}>
                          <img
                            src={findCardImage(cardId)}
                            style={{
                              width: "15px",
                              height: "auto",
                              marginLeft: "3px",
                            }}
                          />
                        </span>
                      ) : null;
                    })}
                  </div>
                  <div className="col-5 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[4]?.gstatus != "1" ? (
                      <button className="back-btn suspended-box">
                        <span>{reqRes?.data?.data?.t2[4]?.rate} </span>
                      </button>
                    ) : (
                      <button
                        className="back-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t2[4]?.rate,
                            reqRes?.data?.data?.t2[4]?.nat,
                            13
                          );
                          setBetIndex(reqRes?.data?.data?.t2[4]?.sid);
                        }}
                      >
                        {" "}
                        <span>{reqRes?.data?.data?.t2[4]?.rate} </span>
                        <p className="text-center text-success">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 13 && (
                                <span
                                  className={`${
                                    ele.number == 13 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 13 && ele.amount}
                                </span>
                              )
                          )}
                        </p>
                      </button>
                    )}
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[12]?.gstatus != "1" ? (
                      <button className="back-btn suspended-box">
                        <span>{reqRes?.data?.data?.t2[12]?.rate} </span>
                      </button>
                    ) : (
                      <button
                        className="back-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t2[12]?.rate,
                            reqRes?.data?.data?.t2[12]?.nat,
                            14
                          );
                          setBetIndex(reqRes?.data?.data?.t2[12]?.sid);
                        }}
                      >
                        {" "}
                        <span>{reqRes?.data?.data?.t2[12]?.nat} </span>
                        <p className="text-center text-success">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 14 && (
                                <span
                                  className={`${
                                    ele.number == 14 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 14 && ele.amount}
                                </span>
                              )
                          )}
                        </p>
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div style={{ borderTop: "2px solid white" }}>
                <div className="table-h-div row">
                  <div className="col-2 d-flex align-items-center ps-2" />

                  <div className="col-2 d-flex align-items-center justify-content-center " />
                </div>
                <div className="card-table-inner-row row">
                  <div className="col-2 d-flex align-items-center ps-2">
                  <b>  Player 6{" "} </b>
                    {[5, 14, 23].map((key, index) => {
                      const cardId = arrayData[key];
                      return cardId ? (
                        <span key={index}>
                          <img
                            src={findCardImage(cardId)}
                            style={{
                              width: "15px",
                              height: "auto",
                              marginLeft: "3px",
                            }}
                          />
                        </span>
                      ) : null;
                    })}
                  </div>
                  <div className="col-5 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[5]?.gstatus != "1" ? (
                      <button className="back-btn suspended-box">
                        <span>{reqRes?.data?.data?.t2[5]?.rate} </span>
                      </button>
                    ) : ( 
                      <button
                        className="back-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t2[5]?.rate,
                            reqRes?.data?.data?.t2[5]?.nat,
                            16
                          );
                          setBetIndex(reqRes?.data?.data?.t2[5]?.sid);
                        }}
                      >
                        <span>{reqRes?.data?.data?.t2[5]?.rate} </span>
                        <p className="text-center text-success">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 16 && (
                                <span
                                  className={`${
                                    ele.number == 16 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 16 && ele.amount}
                                </span>
                              )
                          )}
                        </p>
                      </button>
                    )}
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[13]?.gstatus != "1" ? (
                      <button className="back-btn suspended-box">
                        <span>{reqRes?.data?.data?.t2[13]?.rate} </span>
                      </button>
                    ) : (
                      <button
                        className="back-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t2[13]?.rate,
                            reqRes?.data?.data?.t2[13]?.nat,
                            17
                          );
                          setBetIndex(reqRes?.data?.data?.t2[13]?.sid);
                        }}
                      >
                        {" "}
                        <span>{reqRes?.data?.data?.t2[13]?.nat} </span>
                        <p className="text-center text-success">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 17 && (
                                <span
                                  className={`${ 
                                    ele.number == 17 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 17 && ele.amount}
                                </span>
                              )
                          )}
                        </p>
                      </button>
                    )}
                  </div>
                </div>
                <div className="card-table-inner-row row">
                  <div className="col-2 d-flex align-items-center ps-2">
                 <b>  Player 7{" "} </b> 
                    {[6, 15, 24].map((key, index) => {
                      const cardId = arrayData[key];
                      return cardId ? (
                        <span key={index}>
                          <img
                            src={findCardImage(cardId)}
                            style={{
                              width: "15px",
                              height: "auto",
                              marginLeft: "3px",
                            }}
                          />
                        </span>
                      ) : null;
                    })}
                  </div>
                  <div className="col-5 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[6]?.gstatus != "1" ? (
                      <button className="back-btn suspended-box">
                        <span>{reqRes?.data?.data?.t2[6]?.rate} </span>
                      </button>
                    ) : (
                      <button
                        className="back-btn" 
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t2[6]?.rate,
                            reqRes?.data?.data?.t2[6]?.nat,
                            19
                          );
                          setBetIndex(reqRes?.data?.data?.t2[6]?.sid);
                        }} 
                      >
                        <span>{reqRes?.data?.data?.t2[6]?.rate} </span>
                        <p className="text-center text-success">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 19 && (
                                <span
                                  className={`${
                                    ele.number == 19 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 19 && ele.amount}
                                </span>
                              )
                          )}
                        </p>
                      </button>
                    )}
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[14]?.gstatus != "1" ? (
                      <button className="back-btn suspended-box">
                        <span>{reqRes?.data?.data?.t2[14]?.rate} </span>
                      </button>
                    ) : (
                      <button 
                        className="back-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t2[14]?.rate,
                            reqRes?.data?.data?.t2[14]?.nat,
                            20
                          );
                          setBetIndex(reqRes?.data?.data?.t2[14]?.sid);
                        }}
                      >
                        <span>{reqRes?.data?.data?.t2[14]?.nat} </span>
                        <p className="text-center text-success">
                          {thirtyTwoCardStack.map(
                            (ele) =>   
                              ele.number === 20 && (
                                <span
                                  className={`${
                                    ele.number == 20 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 20 && ele.amount}
                                </span>
                              )
                          )}
                        </p>
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div style={{ borderTop: "2px solid white" }}>
                <div className="card-table-inner-row row">
                  <div className="col-2 d-flex align-items-center ps-2">
                 <b>   Player 8{" "} </b>
                    {[7, 16, 25].map((key, index) => {
                      const cardId = arrayData[key];
                      return cardId ? (
                        <span key={index}>
                          <img
                            src={findCardImage(cardId)}
                            style={{
                              width: "15px",
                              height: "auto",
                              marginLeft: "3px",
                            }}
                          />
                        </span>
                      ) : null;
                    })}
                  </div>
                  <div className="col-5 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[7]?.gstatus != "1" ? (
                      <button className="back-btn suspended-box">
                        <span>{reqRes?.data?.data?.t2[7]?.rate} </span>
                      </button>
                    ) : (
                      <button
                        className="back-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t2[7]?.rate,
                            reqRes?.data?.data?.t2[7]?.nat,
                            22
                          );
                          setBetIndex(reqRes?.data?.data?.t2[7]?.sid);
                        }}
                      >
                        <span>{reqRes?.data?.data?.t2[7]?.rate} </span>
                        <p className="text-center text-success">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 22 && (
                                <span
                                  className={`${
                                    ele.number == 22 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 22 && ele.amount}
                                </span>
                              )
                          )}
                        </p>
                      </button>
                    )}
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[15]?.gstatus != "1" ? (
                      <button className="back-btn suspended-box">
                        <span>{reqRes?.data?.data?.t2[15]?.rate} </span>
                      </button>
                    ) : (
                      <button
                        className="back-btn"
                        onClick={() => {
                          handleModal(
                            reqRes?.data?.data?.t2[15]?.rate,
                            reqRes?.data?.data?.t2[15]?.nat,
                            23
                          );
                          setBetIndex(reqRes?.data?.data?.t2[15]?.sid);
                        }}
                      >
                        <span>{reqRes?.data?.data?.t2[15]?.nat} </span>
                        <p className="text-center text-success">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 23 && (
                                <span
                                  className={`${
                                    ele.number == 23 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 23 && ele.amount}
                                </span>
                              )
                          )}
                        </p>
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="Results-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Last 10 Results</p>
                  </div>
                </div>
                <div className="card rst-card py-1 d-flex">
                  <div className="rst-row d-flex">
                    {results?.data?.data?.map((result, index) => {
                      const item = reqRes?.data?.data?.t2.find(
                        (item) => item.sid === result?.result
                      );

                      return (
                        <div
                          onClick={() => {
                            setMidRound(result?.mid);
                            CasinoGameResult_API(result?.mid);
                            setShowresultModal(true);
                            setTrophy(item?.nat[0]);
                          }}
                          className="rst-btn ms-1"
                          key={index}
                        >
                          {item ? <>{item.nat[0]}</> : "R"}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* <<<<<<<---------My-Bets-table-------------->>>>>>>>>> */}
              <div className="My-Bets-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Open Bets</p>
                  </div>
                </div>

                {HistoryTable}
              </div>
            </div>
          </div>

          <RightSidebar />
          {showModal && (
            <Modal
              id={id}
              name={name}
              betDetails={betDetails}
              setbetDetails={setbetDetails}
              setShowModal={setShowModal}
              realTime={realTime}
              setRealTimeData={setRealTimeData}
            />
          )}
          {showresultModal && <ResultsModal />}
          {/* {showDataModal && <DataModal />} */}
        </div>
      </div>
    </>
  );
}
