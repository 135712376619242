import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import a from "../../Media/10SS.png";
import b from "../../Media/3SS.png";
import { MdCancel } from "react-icons/md";
import { FaTrophy } from "react-icons/fa";
import "./InstantWorli.css";
import { useParams } from "react-router-dom";
import Modal from "../TeenPatti/Modal";
import { io } from "socket.io-client";
import { API } from "../../API/API";
import { useDispatch } from "react-redux";
import { fetchThirtyTwoCardStack } from "../../Redux/Slices/thirtyTwoCardGameSlice";
import { card52War } from "../../Utility/52CardImages.js";
import cardBlank from "../../Media/DTL2020/backcard.webp";


export default function InstantWorli() {
  const { id } = useParams();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showresultModal, setShowresultModal] = useState(false);
  const [showDataModal, setShowdataModal] = useState(false);
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [realTime, setRealTimeData] = useState(false);
  const [betDetails, setbetDetails] = useState({});
  const [betIndex, setBetIndex] = useState("");
  const [history, setHistory] = useState([]);

  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);

  const SOCKET_URL = "https://gamecoin-socket.nakshtech.info/";

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      console.log("CasinoGameResult dragon:", response.data);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  // console.log("casinoGameResult", casinoGameResult);

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);
    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));
        // console.log(
        //   "parsed reqResponse",
        //   parsedData[0].reqResponse.data?.data?.t2[0]?.gstatus
        // );
        // console.log("parsed_result", parsedData[0]);
        // console.log("parsed_result", reqRes?.data?.data?.t2[0]?.gstatus);

        setResults(parsedData[0].resultUrlResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });
    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nat: nat,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.sid === betIndex
      );
      console.log("filter", filteredData, betIndex, betDetails);
      filteredData[0]?.gstatus === "1" //&&
        ? // betDetails.nat === filteredData[0].nat //&&
          // betDetails.multipler === filteredData[0].rate
          setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betIndex]);

  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"></div>
        <div className="results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound}</div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
              }}
            />
          </div>
          <div>
            <div className="d-flex justify-content-center mt-2">
              {/* <div className="player-a-div "> */}
              <div className="d-flex text-center">
                {casinoGameResult[0] && (
                  <div
                    className="gamecard-div mx-1 "
                    key={casinoGameResult[0].id}>
                    <img
                      src={casinoGameResult[0].image}
                      className="img-fluid"
                      alt={`Player A Card 0`}
                    />
                  </div>
                )}
                {casinoGameResult[1] && (
                  <div
                    className="gamecard-div mx-1 "
                    key={casinoGameResult[1].id}>
                    <img
                      src={casinoGameResult[1].image}
                      className="img-fluid"
                      alt={`Player A Card 0`}
                    />
                  </div>
                )}
                {casinoGameResult[2] && (
                  <div
                    className="gamecard-div mx-1 "
                    key={casinoGameResult[2].id}>
                    <img
                      src={casinoGameResult[2].image}
                      className="img-fluid"
                      alt={`Player A Card 0`}
                    />
                  </div>
                )}
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);
    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id]);

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {/* {loading ? (
              <tr>
                <td colSpan="6">Loading ...</td>
              </tr>
            ) : */}
            {history.length > 0 ? (
              history.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  async function ResultScheduler_API() {
    try {
      let res = await API.post("/Schulder_7CareGameLive", {
        gameid: id,
        username: uid
      },
        {
          headers: {
            Authorization: token,
          },
        });
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  const findCardImage = (cardId) => {
    const card = card52War.find((card) => card.id === cardId);
    return card ? card.src : null;
  };

  return (
    <>
      <div className="col-lg-10 ">
        <div className="row home mainhomeDev">
          <div className="col-lg-8 p-1">
            <div className="teenpatti-game-div pt-2 p-2">
              <div className="teenpatti-game-h d-flex justify-content-between  ">
                <div className=" px-2">
                  <p className=" mt-1 ">INSTANT WORLI</p>
                </div>
                <div>
                  <p className="mt-1 px-2">
                    Round ID: {reqRes?.data?.data?.t1[0]?.mid || 0.0} | Left
                    Time:{reqRes?.data?.data?.t1[0]?.autotime || 0} |{" "}
                  </p>
                </div>
              </div>
              <div className="card  teenpatti-game-card p-1 mt-1">
                <div className="row">
                  <div className="col-10">
                    <div className="" style={{ height: "170%", width: "120%" }}>
                      <iframe
                        height="100%"
                        width="100%"
                        src={`https://battlemoney.live/iframe/${id}`}
                        title=""
                      />
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-end">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="timer ">
                    <div
                      className="container"
                      style={{ color: "white",marginLeft:"-5px",  marginTop:'-150px'}}
                    >
                      {/* <div className="timer_container position-absolute"  > */}
                      <span></span>
                      <div>
                        {["C1","C2","C3"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId) || cardBlank}
                                alt={cardId}
                                style={{ width: "23px", height: "auto", marginRight:"2px" }}
                              />
                            </span>   
                          ) : null;
                        })}
                      </div>
                      {/* <span>
                      <span>Player B</span>
                      <div>
                        {["C3", "C4"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId)}
                                alt={cardId}
                                style={{ width: "20px", height: "auto" }}
                              />
                            </span>
                          ) : null;
                        })}
                      </div>
                      </span>  */}
                      {/* Board */}

                      {/* <span>
                      <span>Board</span>
                      <div>
                        {["C5", "C6","C7","C8"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId)}
                                alt={cardId}
                                style={{ width: "20px", height: "auto" }}
                              />
                            </span>
                          ) : null;
                        })}
                      </div>
                      </span>  */}


                     

                      {/* </div> */}
                    </div>
                  </div>
                  <div
                    className="timer "
                    style={{ marginLeft: "550px", marginTop: "20px" }}>
                    <div className="container">
                    <div
                      className="timer_container position-absolute"
                      style={{ right: "36px", bottom: "15px" }}
                    >
                      <button
                        class="btn btn-secondary"
                        style={{ cursor: "default", background: "#6f42c1" }}
                      >
                        <span>
                          {" "}
                          {reqRes?.data?.data?.t1[0]?.autotime || 0}{" "}
                        </span>
                      </button>{" "}
                    </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <-------------------------------Back-lay-table----------> */}
              {/* <div>
                <div className="table-h-div row">
                  <div className="col-8 d-flex align-items-center justify-content-center px-2">
                    9
                  </div>
                  <div className="col-4 d-flex align-items-center justify-content-center px-2">
                    9
                  </div>
                </div>
                <div className="col-12">

                </div>
              </div> */}
              <div className="worlibox">
                <div className="worli-left ">
                  <div className="worli-box-title ">
                    <b className=""> 9 </b>
                  </div>
                  <div className="worli-box-row ">
                    <div className="worli-odd-box back  ">
                      {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                        <>
                          <div className="worli-odd-box back  ">
                            <span className="worli-odd  ">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            className="worli-odd-box back"
                            onClick={() => {
                              handleModal(9, "1 Single", 1);
                              setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                            }}>
                            <span className="worli-odd">1</span>
                          </button>
                        </>
                      )}
                    </div>
                    <div className="worli-odd-box back  ">
                      {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                        <>
                          <div className="worli-odd-box back  ">
                            <span className="worli-odd  ">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            className="worli-odd-box back"
                            onClick={() => {
                              handleModal(9, "2 Single", 2);
                              setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                            }}>
                            <span className="worli-odd">2</span>
                          </button>
                        </>
                      )}
                    </div>
                    <div className="worli-odd-box back  ">
                      {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                        <>
                          <div className="worli-odd-box back  ">
                            <span className="worli-odd  ">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            className="worli-odd-box back"
                            onClick={() => {
                              handleModal(9, "3 Single", 3);
                              setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                            }}>
                            <span className="worli-odd">3</span>
                          </button>
                        </>
                      )}
                    </div>
                    <div className="worli-odd-box back  ">
                      {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                        <>
                          <div className="worli-odd-box back  ">
                            <span className="worli-odd  ">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            className="worli-odd-box back"
                            onClick={() => {
                              handleModal(9, "4 Single", 4);
                              setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                            }}>
                            <span className="worli-odd">4</span>
                          </button>
                        </>
                      )}
                    </div>
                    <div className="worli-odd-box back  ">
                      {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                        <>
                          <div className="worli-odd-box back  ">
                            <span className="worli-odd  ">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            className="worli-odd-box back"
                            onClick={() => {
                              handleModal(9, "5 Single", 5);
                              setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                            }}>
                            <span className="worli-odd">5</span>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="worli-box-row ">
                    <div className="worli-odd-box back  ">
                      {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                        <>
                          <div className="worli-odd-box back  ">
                            <span className="worli-odd  ">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            className="worli-odd-box back"
                            onClick={() => {
                              handleModal(9, "6 Single", 6);
                              setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                            }}>
                            <span className="worli-odd">6</span>
                          </button>
                        </>
                      )}
                    </div>
                    <div className="worli-odd-box back  ">
                      {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                        <>
                          <div className="worli-odd-box back  ">
                            <span className="worli-odd  ">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            className="worli-odd-box back"
                            onClick={() => {
                              handleModal(9, "7 Single", 7);
                              setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                            }}>
                            <span className="worli-odd">7</span>
                          </button>
                        </>
                      )}
                    </div>
                    <div className="worli-odd-box back  ">
                      {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                        <>
                          <div className="worli-odd-box back  ">
                            <span className="worli-odd  ">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            className="worli-odd-box back"
                            onClick={() => {
                              handleModal(9, "8 Single", 8);
                              setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                            }}>
                            <span className="worli-odd">8</span>
                          </button>
                        </>
                      )}
                    </div>
                    <div className="worli-odd-box back  ">
                      {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                        <>
                          <div className="worli-odd-box back  ">
                            <span className="worli-odd  ">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            className="worli-odd-box back"
                            onClick={() => {
                              handleModal(9, "9 Single", 9);
                              setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                            }}>
                            <span className="worli-odd">9</span>
                          </button>
                        </>
                      )}
                    </div>
                    <div className="worli-odd-box back  ">
                      {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                        <>
                          <div className="worli-odd-box back  ">
                            <span className="worli-odd  ">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            className="worli-odd-box back"
                            onClick={() => {
                              handleModal(9, "0 Single", 10);
                              setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                            }}>
                            <span className="worli-odd">0</span>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="worli-right ">
                  <div className="worli-box-title  ">
                    <b className=""> 9 </b>
                  </div>

                  <div className="worli-box-row ">
                    <div className="worli-odd-box back  ">
                      {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                        <>
                          <div className="worli-odd-box back  ">
                            <span className="worli-odd  ">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            className="worli-odd-box back"
                            onClick={() => {
                              handleModal(9, "Line 1 Single", 11);
                              setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                            }}>
                            <span className=" ">
                              <b className=""> Line 1</b>
                            </span>

                            <span className="d-block  ">1|2|3|4|5 </span>
                          </button>
                        </>
                      )}
                    </div>
                    <div className="worli-odd-box back  ">
                      {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                        <>
                          <div className="worli-odd-box back  ">
                            <span className="worli-odd  ">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            className="worli-odd-box back"
                            onClick={() => {
                              handleModal(9, "Odd Single", 12);
                              setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                            }}>
                            <span className=" ">
                              <b className=""> ODD </b>
                            </span>

                            <span className="d-block  ">1|3|5|7|9 </span>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="worli-box-row ">
                    <div className="worli-odd-box back  ">
                      {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                        <>
                          <div className="worli-odd-box back  ">
                            <span className="worli-odd  ">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            className="worli-odd-box back"
                            onClick={() => {
                              handleModal(9, "Line 2 Single", 13);
                              setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                            }}>
                            <span className=" ">
                              <b className=""> Line 2 </b>
                            </span>

                            <span className="d-block  ">6|7|8|9|0 </span>
                          </button>
                        </>
                      )}
                    </div>
                    <div className="worli-odd-box back  ">
                      {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                        <>
                          <div className="worli-odd-box back  ">
                            <span className="worli-odd  ">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            className="worli-odd-box back"
                            onClick={() => {
                              handleModal(9, "Even Single", 14);
                              setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                            }}>
                            <span className=" ">
                              <b className=""> EVEN </b>
                            </span>

                            <span className="d-block  ">2|4|6|8|0 </span>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* ===================++++++++============== */}
              <div className="Results-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Last 10 Results</p>
                  </div>
                </div>
                <div className="card rst-card py-1 d-flex">
                  <div className="rst-row d-flex">
                    {results?.data?.data?.map((result, index) => {
                      const item = reqRes?.data?.data?.t2.find(
                        (item) => item.sid === result?.result
                      );

                      return (
                        <div
                          onClick={() => {
                            setMidRound(result.mid);
                            CasinoGameResult_API(result.mid);
                            setShowresultModal(true);
                          }}
                          className="rst-btn ms-1"
                          key={index}>
                          {<>R</>}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* <<<<<<<---------My-Bets-table-------------->>>>>>>>>> */}
              <div className="My-Bets-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Open Bets</p>
                  </div>
                </div>
                {HistoryTable}
                {/* <div className="card-table-inner-row row"></div> */}
              </div>
            </div>
          </div>

          <RightSidebar />
          {showModal && (
            <Modal
              id={id}
              name={name}
              betDetails={betDetails}
              setbetDetails={setbetDetails}
              setShowModal={setShowModal}
              realTime={realTime}
              setRealTimeData={setRealTimeData}
            />
          )}

          {showresultModal && <ResultsModal />}
        </div>
      </div>
    </>
  );
}
