import React, { useEffect, useState } from "react";
import OddsTable2 from "../SportNew/Table/OddsTable2";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import SportModal from "../SportNew/SportModal";
import { saveTeams } from "../../Redux/Slices/SaveTeamsSlice";
import { fetchbetStackData } from "../../Redux/api/betStackAPI";

const FootballOdds = () => {
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");

  const SOCKET_URL = "https://gamecoin-socket.nakshtech.info/";
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.auth.currentMatch);
  const { oddStack } = useSelector((state) => state.betStack);

  const [socketData, setSocketData] = useState([]);
  const [modal, setModal] = useState(false);
  const [marketType, setmarketType] = useState(null);
  const [betType, setbetType] = useState(null);
  const [betDetails, setbetDetails] = useState({});
  const [countryName, setcountryName] = useState(null);
  const [show, setShow] = useState(0);
  const [dataValue, setDataValue] = useState("");
  const [realtimeData, setRealtimeData] = useState(false);

  useEffect(() => {
    const FootballMatchesSocket = io(SOCKET_URL);
    FootballMatchesSocket.emit("SoccerAllOddMatchType", id);
    FootballMatchesSocket.on("SoccerAllOddMatchType_FromAPI", (data) => {
      let parsedata = JSON.parse(data);
      console.log("match_football", parsedata);
      if (parsedata.statusCode === 200) {
        setSocketData(parsedata?.data?.matchOddsResponseDTO);
      }
    });
    return () => {
      FootballMatchesSocket.disconnect();
    };
  }, [id]);

  const handleModal = () => {
    setModal(!modal);
  };

  const checkValid = () => {
    if (realtimeData && marketType === "Match Odds") {
      const filteredData = socketData[0].oddDatas?.filter(
        (item) => item.rname == dataValue?.team && item.sid == dataValue?.selId
      );

      if (betType === "back") {
        if (
          filteredData[0].sid == dataValue?.selId &&
          filteredData[0].status != "SUSPENDED" &&
          filteredData[0]?.b1 == dataValue?.valueOf &&
          filteredData[0]?.rname == dataValue?.team
        ) {
          return;
        } else {
          setRealtimeData(false);
          setDataValue({});
        }
      } else if (betType === "lay") {
        if (
          filteredData[0].sid == dataValue?.selId &&
          filteredData[0].status != "SUSPENDED" &&
          filteredData[0]?.l1 == dataValue?.valueOf &&
          filteredData[0]?.rname == dataValue?.team
        ) {
          return;
        } else {
          setRealtimeData(false);
          setDataValue({});
        }
      }
    }
  };

  useEffect(() => {
    checkValid();
  }, [realtimeData, dataValue, socketData]);

  useEffect(() => {
    dispatch(
      fetchbetStackData({
        userId: uid,
        mId: id,
        matchType: "Match Odds",
        token,
      })
    );
  }, [dispatch, id]);

  const [count, setCount] = useState(0);

  const SaveTeams_API = async (mname, team, sid) => {
    dispatch(saveTeams({ id, mname, team, token, sid }));
  };

  useEffect(() => {
    if (
      socketData &&
      socketData[0]?.oddDatas &&
      socketData[0]?.oddDatas.length > 0 &&
      count < 1
    ) {
      socketData[0]?.oddDatas?.forEach((runner) => {
        SaveTeams_API("Match Odds", runner.rname, runner.sid);
      });
      setCount(count + 1);
    }
  }, [socketData]);

  return (
    <div>
      <div className="row  bg-dark ">
        <div className="Match-ods-h d-flex align-items-center">
          <p className="mx-2 mt-3 d-flex">
            MATCH ODDS <div className="green-live-dot ms-2 mt-2"></div>
          </p>
        </div>
        <div className="row back-lay-row  bg-dark ">
          <div className="col-7 "></div>
          <div className="col-5 d-flex">
            <button className="back ">Back</button>
            <button className="lay ms-1">Lay</button>
          </div>
        </div>
        <div className="betting-team-info">
          {socketData &&
            socketData.length > 0 &&
            socketData[0]?.oddDatas?.map((item, index) => (
              <OddsTable2
                key={index}
                data={item}
                mid={socketData[0].mid}
                matchType="Match Odds"
                handleModal={handleModal}
                setbetType={setbetType}
                setmarketType={setmarketType}
                setbetDetails={setbetDetails}
                setRealtimeData={setRealtimeData}
                setDataValue={setDataValue}
                Teamexp={oddStack?.filter((ele) => ele.team == item?.rname)}
              />
            ))}

          {((socketData && socketData.length === 0) ||
            socketData[0]?.oddDatas.length == 0) && (
            <div className="text-center py-2">No Bet !</div>
          )}
        </div>
      </div> 

      {modal && (
        <SportModal
          setModal={setModal}
          setbetType={setbetType}
          setmarketType={setmarketType}
          setbetDetails={setbetDetails}
          betDetails={betDetails}
          betType={betType}
          marketType={marketType}
          countryName={countryName}
          realtimeData={realtimeData}
          setRealtimeData={setRealtimeData}
        />
      )}
    </div>
  );
};

export default FootballOdds;
