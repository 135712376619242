import React, { useState, useEffect } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import a from "../../Media/10SS.png";
import b from "../../Media/3SS.png";
import { MdCancel } from "react-icons/md";
import { FaTrophy } from "react-icons/fa";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./Poker.css";
import { fetchThirtyTwoCardStack } from "../../Redux/Slices/thirtyTwoCardGameSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { API } from "../../API/API";
import Modal from "../TeenPatti/Modal";
import { card52War } from "../../Utility/52CardImages.js";
import cardBlank from "../../Media/DTL2020/backcard.webp";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PokerSix() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");

  const [showresultModal, setShowresultModal] = useState(false);
  const [showDataModal, setShowdataModal] = useState(false);

  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [rulesModal, setRulesModal] = useState(false);
  const [realTime, setRealTimeData] = useState(false);
  const [betIndex, setBetIndex] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [betDetails, setbetDetails] = useState({});
  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const [history, setHistory] = useState([]);
  const [trophy, setTrophy] = useState("");
  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );
  const resultMapping = {
    11: "1",
    12: "2",
    13: "3",
    14: "4",
    15: "5",
    16: "6",
  };
  const SOCKET_URL = "https://gamecoin-socket.nakshtech.info/";
  const [value, setValue] = React.useState(0);

  console.log("trophy", trophy);
  console.log("results", results);

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      console.log("CasinoGameResult dragon:", response.data.data[0]);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"
        ></div>
        <div className="results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound}</div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
              }}
            />
          </div>
          <div>
            <div className="container d-flex">
              <div className="row">
                <div className="d-flex justify-content-center mt-2">
                  <div className="player-a-div  ">
                    <div className="d-flex justify-content-between mt-4">
                      <div className="text-center">1</div>
                      {trophy === "1" && <FaTrophy className="trophy-icon" />}
                    </div>

                    <div className="d-flex">
                      {casinoGameResult[0] && (
                        <div
                          className="gamecard-div mx-1 "
                          key={casinoGameResult[0].id}
                        >
                          <img
                            src={casinoGameResult[0].image}
                            className="img-fluid"
                            alt={`Player A Card 0`}
                          />
                        </div>
                      )}
                      {casinoGameResult[6] && (
                        <div
                          className="gamecard-div mx-1"
                          key={casinoGameResult[6].id}
                        >
                          <img
                            src={casinoGameResult[6].image}
                            className="img-fluid"
                            alt={`Player A Card 0`}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <div className="player-a-div ">
                    <div className="d-flex justify-content-between mt-2">
                      <div className="text-center">2</div>
                      {trophy === "2" && <FaTrophy className="trophy-icon" />}
                    </div>
                    <div className="d-flex">
                      {casinoGameResult[1] && (
                        <div
                          className="gamecard-div mx-1"
                          key={casinoGameResult[1].id}
                        >
                          <img
                            src={casinoGameResult[1].image}
                            className="img-fluid"
                            alt={`Player A Card 0`}
                          />
                        </div>
                      )}
                      {casinoGameResult[7] && (
                        <div
                          className="gamecard-div mx-1"
                          key={casinoGameResult[7].id}
                        >
                          <img
                            src={casinoGameResult[7].image}
                            className="img-fluid"
                            alt={`Player A Card 0`}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <div className="player-a-div ">
                    <div className="d-flex justify-content-between mt-2">
                      <div className="text-center">3</div>
                      {trophy === "3" && <FaTrophy className="trophy-icon" />}
                    </div>
                    <div className="d-flex">
                      {casinoGameResult[2] && (
                        <div
                          className="gamecard-div mx-1"
                          key={casinoGameResult[2].id}
                        >
                          <img
                            src={casinoGameResult[2].image}
                            className="img-fluid"
                            alt={`Player A Card 0`}
                          />
                        </div>
                      )}
                      {casinoGameResult[8] && (
                        <div
                          className="gamecard-div mx-1"
                          key={casinoGameResult[8].id}
                        >
                          <img
                            src={casinoGameResult[8].image}
                            className="img-fluid"
                            alt={`Player A Card 0`}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-2">
                <div className="player-a-div ">
                  <div className="text-center">Board Card</div>
                  <div className="d-flex">
                    {casinoGameResult.slice(12, 17).map((item) => (
                      <div className="gamecard-div mx-1" key={item.id}>
                        <img
                          src={item.image}
                          className="img-fluid"
                          alt={`Player A Card 0`}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="d-flex justify-content-center mt-2">
                  <div className="player-a-div  ">
                    <div className="d-flex justify-content-between mt-4">
                      <div className="text-center">4</div>
                      {trophy === "4" && <FaTrophy className="trophy-icon" />}
                    </div>

                    <div className="d-flex">
                      {casinoGameResult[3] && (
                        <div
                          className="gamecard-div mx-1"
                          key={casinoGameResult[3].id}
                        >
                          <img
                            src={casinoGameResult[3].image}
                            className="img-fluid"
                            alt={`Player A Card 0`}
                          />
                        </div>
                      )}
                      {casinoGameResult[9] && (
                        <div
                          className="gamecard-div mx-1"
                          key={casinoGameResult[9].id}
                        >
                          <img
                            src={casinoGameResult[9].image}
                            className="img-fluid"
                            alt={`Player A Card 0`}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <div className="player-a-div ">
                    <div className="d-flex justify-content-between mt-2">
                      <div className="text-center">5</div>
                      {trophy === "5" && <FaTrophy className="trophy-icon" />}
                    </div>
                    <div className="d-flex">
                      {casinoGameResult[4] && (
                        <div
                          className="gamecard-div mx-1"
                          key={casinoGameResult[4].id}
                        >
                          <img
                            src={casinoGameResult[4].image}
                            className="img-fluid"
                            alt={`Player A Card 0`}
                          />
                        </div>
                      )}
                      {casinoGameResult[10] && (
                        <div
                          className="gamecard-div mx-1"
                          key={casinoGameResult[10].id}
                        >
                          <img
                            src={casinoGameResult[10].image}
                            className="img-fluid"
                            alt={`Player A Card 0`}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <div className="player-a-div ">
                    <div className="d-flex justify-content-between mt-2">
                      <div className="text-center">6</div>
                      {trophy === "6" && <FaTrophy className="trophy-icon" />}
                    </div>
                    <div className="d-flex">
                      {casinoGameResult[5] && (
                        <div
                          className="gamecard-div mx-1"
                          key={casinoGameResult[5].id}
                        >
                          <img
                            src={casinoGameResult[5].image}
                            className="img-fluid"
                            alt={`Player A Card 0`}
                          />
                        </div>
                      )}
                      {casinoGameResult[11] && (
                        <div
                          className="gamecard-div mx-1"
                          key={casinoGameResult[11].id}
                        >
                          <img
                            src={casinoGameResult[11].image}
                            className="img-fluid"
                            alt={`Player A Card 0`}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {/* {loading ? (
              <tr>
                <td colSpan="6">Loading ...</td>
              </tr>
            ) : */}
            {history.length > 0 ? (
              history.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  async function ResultScheduler_API() {
    try {
      let res = await API.post(
        "/Schulder_7CareGameLive",
        {
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nat: nat,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);
    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id]);

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);
    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      //  console.log("GetCasinoGameSocket",data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        console.log("reqResponse", parsedData[0].reqResponse);
        // console.log("resultUrlResponse", parsedData[0].resultUrlResponse);

        setResults(parsedData[0].resultUrlResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.sid === betIndex
      );

      // console.log("filter", filteredData, betDetails);

      // console.log("gstatus", filteredData[0]?.gstatus);
      // console.log("betDetails.nat", betDetails.nat);
      // console.log("filteredData[0].nat", filteredData[0].nat);
      // console.log("betDetails.multipler", betDetails.multipler);
      // console.log("filteredData[0].rate", filteredData[0].rate);

      filteredData[0]?.gstatus == "1" &&
      betDetails.nat === filteredData[0].nat &&
      betDetails.multipler === filteredData[0].rate
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  const findCardImage = (cardId) => {
    const card = card52War.find((card) => card.id === cardId);
    return card ? card.src : null;
  };

  return (
    <>
      <div className="col-lg-10 ">
        <div className="row home mainhomeDev">
          <div className="col-lg-8 p-1">
            <div className="teenpatti-game-div pt-2 p-2">
              <div className="teenpatti-game-h d-flex justify-content-between  ">
                <div className=" px-2">
                  <p className=" mt-1 ">6 PLAYER POKER</p>
                </div>
                <div>
                  <p className="mt-1 px-2">
                    Round ID: {reqRes?.data?.data?.t1[0]?.mid || 0.0} | Left
                    Time: {reqRes?.data?.data?.t1[0]?.autotime || 0} | Rules
                  </p>
                </div>
              </div>
              <div className="card  teenpatti-game-card p-1 mt-1">
                <div className="row">
                  <div className="col-10">
                    <div className="" style={{ height: "170%", width: "120%" }}>
                      <iframe
                        height="100%"
                        width="100%"
                        src={`https://battlemoney.live/iframe/${id}`}
                        title=""
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-end">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="timer ">
                    <div
                      className="container"
                      style={{
                        color: "white",
                        marginLeft: "-5px",
                        marginTop: "-150px",
                      }}
                    >
                      {/* <div className="timer_container position-absolute"  > */}
                      <span></span>
                      <div>
                        {["C13", "C14", "C15", "C16", "C17"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId) || cardBlank}
                                alt={cardId}
                                style={{
                                  width: "22px",
                                  height: "auto",
                                  marginRight: "3px",
                                }}
                              />
                            </span>
                          ) : null;
                        })}
                      </div>
                    </div>
                  </div>
                  <div
                    className="timer "
                    style={{ marginLeft: "550px", marginTop: "20px" }}
                  >
                    <div className="container">
                    <div
                      className="timer_container position-absolute"
                      style={{ right: "36px", bottom: "15px" }}
                    >
                      <button
                        class="btn btn-secondary"
                        style={{ cursor: "default", background: "#6f42c1" }}
                      >
                        <span>
                          {" "}
                          {reqRes?.data?.data?.t1[0]?.autotime || 0}{" "}
                        </span>
                      </button>{" "}
                    </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <-------------------------------Back-lay-table----------> */}

              <div className="bg-light">
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab label="Hands" {...a11yProps(0)} />
                      <Tab label="Pattern" {...a11yProps(1)} />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <div className="col-12 ">
                      <div className="rowpoker g-2 row">
                        <div className="col-12 col-md-6 poker-6player">
                          <div className="">
                            {reqRes?.data?.data?.t2[0]?.gstatus != "1" ? (
                              <span className="casino-odds-point suspended-box">
                                <div className="casinooddsbox">
                                  <div className=" casino6poker-item ">
                                    <div className="casino-nation-name d-flex">
                                      {" "}
                                      {reqRes?.data?.data?.t2[0]?.nat != null
                                        ? reqRes?.data?.data?.t2[0]?.nat
                                        : "Player 1"}
                                    </div>

                                    <span className="casino-odds-point">
                                      <span>
                                        {reqRes?.data?.data?.t2[0]?.rate}{" "}
                                      </span>
                                      <div
                                        className="text-center text-success"
                                        style={{ minHeight: "20px" }}
                                      >
                                        {" "}
                                      </div>
                                    </span>
                                  </div>
                                </div>
                              </span>
                            ) : (
                              <div
                                className="casinooddsbox"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[0]?.rate,
                                    reqRes?.data?.data?.t2[0]?.nat,
                                    1
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <div className="casino6poker-item ">
                                  <div className="casino-nation-name d-flex">
                                    {" "}
                                    {reqRes?.data?.data?.t2[0]?.nat != null
                                      ? reqRes?.data?.data?.t2[0]?.nat
                                      : "Player 1"}
                                  </div>
                                  <span className="casino-odds-point">
                                    <span>
                                      {reqRes?.data?.data?.t2[0]?.rate}{" "}
                                    </span>
                                    <div
                                      className="text-center text-success"
                                      style={{ minHeight: "20px" }}
                                    >
                                      {thirtyTwoCardStack.map(
                                        (ele, index) =>
                                          ele.number === 1 && (
                                            <span
                                              className={`${
                                                ele.number === 1 &&
                                                ele.amount >= 0
                                                  ? "success-1"
                                                  : "zero"
                                              }`}
                                              key={index}
                                            >
                                              {ele.number === 1 && ele.amount}
                                            </span>
                                          )
                                      )}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 poker-6player">
                          <div className="">
                            {reqRes?.data?.data?.t2[1]?.gstatus != "1" ? (
                              <span className="casino-odds-point suspended-box">
                                <div className="casinooddsbox">
                                  <div className="casino6poker-item">
                                    <div className="casino-nation-name d-flex">
                                      {reqRes?.data?.data?.t2[1]?.nat != null
                                        ? reqRes?.data?.data?.t2[1]?.nat
                                        : "Player 2"}
                                    </div>
                                    <div className="">
                                      <span className="casino-odds-point">
                                        <span>
                                          {reqRes?.data?.data?.t2[1]?.rate}{" "}
                                        </span>
                                        <div
                                          className="text-center text-success"
                                          style={{ minHeight: "20px" }}
                                        >
                                          {" "}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            ) : (
                              <div
                                className="casinooddsbox"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[1]?.rate,
                                    reqRes?.data?.data?.t2[1]?.nat,
                                    2
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                                }}
                              >
                                <div className="casino6poker-item">
                                  <div className="casino-nation-name d-flex">
                                    {reqRes?.data?.data?.t2[1]?.nat != null
                                      ? reqRes?.data?.data?.t2[1]?.nat
                                      : "Player 2"}
                                  </div>
                                  <span className="casino-odds-point">
                                    <span>
                                      {reqRes?.data?.data?.t2[1]?.rate}{" "}
                                    </span>
                                    <div
                                      className="text-center text-success"
                                      style={{ minHeight: "20px" }}
                                    >
                                      {thirtyTwoCardStack.map(
                                        (ele, index) =>
                                          ele.number === 2 && (
                                            <span
                                              className={`${
                                                ele.number === 2 &&
                                                ele.amount >= 0
                                                  ? "success-1"
                                                  : "zero"
                                              }`}
                                              key={index}
                                            >
                                              {ele.number === 2 && ele.amount}
                                            </span>
                                          )
                                      )}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            )}

                            {/* </span> */}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 poker-6player">
                          <div className="">
                            {reqRes?.data?.data?.t2[2]?.gstatus != "1" ? (
                              <span className="casino-odds-point suspended-box">
                                <div className="casinooddsbox">
                                  <div className=" casino6poker-item ">
                                    <div className="casino-nation-name d-flex">
                                      {" "}
                                      {reqRes?.data?.data?.t2[2]?.nat != null
                                        ? reqRes?.data?.data?.t2[2]?.nat
                                        : "Player 3"}
                                    </div>
                                    <div className="">
                                      <span className="casino-odds-point">
                                        <span>
                                          {reqRes?.data?.data?.t2[2]?.rate}{" "}
                                        </span>
                                        <div
                                          className="text-center text-success"
                                          style={{ minHeight: "20px" }}
                                        >
                                          {" "}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            ) : (
                              <div
                                className="casinooddsbox"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[2]?.rate,
                                    reqRes?.data?.data?.t2[2]?.nat,
                                    3
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                                }}
                              >
                                <div className="casino6poker-item ">
                                  <div className="casino-nation-name d-flex">
                                    {" "}
                                    {reqRes?.data?.data?.t2[2]?.nat != null
                                      ? reqRes?.data?.data?.t2[2]?.nat
                                      : "Player 3"}
                                  </div>
                                  <span className="casino-odds-point">
                                    <span>
                                      {reqRes?.data?.data?.t2[2]?.rate}{" "}
                                    </span>
                                    <div
                                      className="text-center text-success"
                                      style={{ minHeight: "20px" }}
                                    >
                                      {thirtyTwoCardStack.map(
                                        (ele, index) =>
                                          ele.number === 3 && (
                                            <span
                                              className={`${
                                                ele.number === 3 &&
                                                ele.amount >= 0
                                                  ? "success-1"
                                                  : "zero"
                                              }`}
                                              key={index}
                                            >
                                              {ele.number === 3 && ele.amount}
                                            </span>
                                          )
                                      )}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            )}

                            {/* </span> */}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 poker-6player">
                          <div className="">
                            {reqRes?.data?.data?.t2[3]?.gstatus != "1" ? (
                              <span className="casino-odds-point suspended-box">
                                <div className="casinooddsbox ">
                                  <div className=" casino6poker-item ">
                                    <div className="casino-nation-name d-flex">
                                      {" "}
                                      {reqRes?.data?.data?.t2[3]?.nat != null
                                        ? reqRes?.data?.data?.t2[3]?.nat
                                        : "Player 4"}
                                    </div>
                                    <div className="">
                                      <span className="casino-odds-point">
                                        <span>
                                          {reqRes?.data?.data?.t2[3]?.rate}{" "}
                                        </span>
                                        <div
                                          className="text-center text-success"
                                          style={{ minHeight: "20px" }}
                                        >
                                          {" "}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            ) : (
                              <div
                                className="casinooddsbox"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[3]?.rate,
                                    reqRes?.data?.data?.t2[3]?.nat,
                                    4
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[3]?.sid);
                                }}
                              >
                                <div className="casino6poker-item ">
                                  <div className="casino-nation-name d-flex">
                                    {" "}
                                    {reqRes?.data?.data?.t2[3]?.nat != null
                                      ? reqRes?.data?.data?.t2[3]?.nat
                                      : "Player 4"}
                                  </div>
                                  <span className="casino-odds-point">
                                    <span>
                                      {reqRes?.data?.data?.t2[3]?.rate}{" "}
                                    </span>
                                    <div
                                      className="text-center text-success"
                                      style={{ minHeight: "20px" }}
                                    >
                                      {thirtyTwoCardStack.map(
                                        (ele, index) =>
                                          ele.number === 4 && (
                                            <span
                                              className={`${
                                                ele.number === 4 &&
                                                ele.amount >= 0
                                                  ? "success-1"
                                                  : "zero"
                                              }`}
                                              key={index}
                                            >
                                              {ele.number === 4 && ele.amount}
                                            </span>
                                          )
                                      )}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            )}

                            {/* </span> */}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 poker-6player">
                          <div className="">
                            {reqRes?.data?.data?.t2[4]?.gstatus != "1" ? (
                              <span className="casino-odds-point suspended-box">
                                <div className="casinooddsbox ">
                                  <div className=" casino6poker-item ">
                                    <div className="casino-nation-name d-flex">
                                      {" "}
                                      {reqRes?.data?.data?.t2[4]?.nat != null
                                        ? reqRes?.data?.data?.t2[4]?.nat
                                        : "Player 5"}
                                    </div>
                                    <div className="">
                                      <span className="casino-odds-point">
                                        <span>
                                          {reqRes?.data?.data?.t2[4]?.rate}{" "}
                                        </span>
                                        <div
                                          className="text-center text-success"
                                          style={{ minHeight: "20px" }}
                                        >
                                          {" "}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            ) : (
                              <div
                                className="casinooddsbox"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[4]?.rate,
                                    reqRes?.data?.data?.t2[4]?.nat,
                                    5
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[4]?.sid);
                                }}
                              >
                                <div className="casino6poker-item ">
                                  <div className="casino-nation-name d-flex">
                                    {" "}
                                    {reqRes?.data?.data?.t2[4]?.nat != null
                                      ? reqRes?.data?.data?.t2[4]?.nat
                                      : "Player 5"}
                                  </div>
                                  <span className="casino-odds-point">
                                    <span>
                                      {reqRes?.data?.data?.t2[4]?.rate}{" "}
                                    </span>
                                    <div
                                      className="text-center text-success"
                                      style={{ minHeight: "20px" }}
                                    >
                                      {thirtyTwoCardStack.map(
                                        (ele, index) =>
                                          ele.number === 5 && (
                                            <span
                                              className={`${
                                                ele.number === 5 &&
                                                ele.amount >= 0
                                                  ? "success-1"
                                                  : "zero"
                                              }`}
                                              key={index}
                                            >
                                              {ele.number === 5 && ele.amount}
                                            </span>
                                          )
                                      )}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            )}

                            {/* </span> */}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 poker-6player">
                          <div className="">
                            {reqRes?.data?.data?.t2[5]?.gstatus != "1" ? (
                              <span className="casino-odds-point suspended-box">
                                <div className="casinooddsbox ">
                                  <div className=" casino6poker-item ">
                                    <div className="casino-nation-name d-flex">
                                      {" "}
                                      {reqRes?.data?.data?.t2[5]?.nat != null
                                        ? reqRes?.data?.data?.t2[5]?.nat
                                        : "Player 6"}
                                    </div>
                                    <div className="">
                                      <span className="casino-odds-point">
                                        <span>
                                          {reqRes?.data?.data?.t2[5]?.rate}{" "}
                                        </span>
                                        <div
                                          className="text-center text-success"
                                          style={{ minHeight: "20px" }}
                                        >
                                          {" "}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            ) : (
                              <div
                                className="casinooddsbox"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[5]?.rate,
                                    reqRes?.data?.data?.t2[5]?.nat,
                                    6
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[5]?.sid);
                                }}
                              >
                                <div className="casino6poker-item ">
                                  <div className="casino-nation-name d-flex">
                                    {" "}
                                    {reqRes?.data?.data?.t2[5]?.nat != null
                                      ? reqRes?.data?.data?.t2[5]?.nat
                                      : "Player 6"}
                                  </div>
                                  <span className="casino-odds-point">
                                    <span>
                                      {reqRes?.data?.data?.t2[5]?.rate}{" "}
                                    </span>
                                    <div
                                      className="text-center text-success"
                                      style={{ minHeight: "20px" }}
                                    >
                                      {thirtyTwoCardStack.map(
                                        (ele, index) =>
                                          ele.number === 6 && (
                                            <span
                                              className={`${
                                                ele.number === 6 &&
                                                ele.amount >= 0
                                                  ? "success-1"
                                                  : "zero"
                                              }`}
                                              key={index}
                                            >
                                              {ele.number === 6 && ele.amount}
                                            </span>
                                          )
                                      )}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            )}

                            {/* </span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <div className="col-12 ">
                      <div className="rowpoker g-2 row">
                        <div className="col-12 col-sm-6 col-md-4  poker-6player">
                          <div className="">
                            {reqRes?.data?.data?.t2[6]?.gstatus != "1" ? (
                              <span className="casino-odds-point suspended-box">
                                <div className="casinooddsbox ">
                                  <div className=" casino6poker-item ">
                                    <div className="casino-nation-name d-flex">
                                      {" "}
                                      {reqRes?.data?.data?.t2[6]?.nat != null
                                        ? reqRes?.data?.data?.t2[6]?.nat
                                        : "High Card"}
                                    </div>
                                    <div className="">
                                      <span className="casino-odds-point">
                                        <span>
                                          {reqRes?.data?.data?.t2[6]?.rate}{" "}
                                        </span>
                                        <div
                                          className="text-center text-success"
                                          style={{ minHeight: "16px" }}
                                        >
                                          {" "}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            ) : (
                              <div
                                className="casinooddsbox"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[6]?.rate,
                                    reqRes?.data?.data?.t2[6]?.nat,
                                    7
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[6]?.sid);
                                }}
                              >
                                <div className="casino6poker-item ">
                                  <div className="casino-nation-name d-flex">
                                    {" "}
                                    {reqRes?.data?.data?.t2[6]?.nat != null
                                      ? reqRes?.data?.data?.t2[6]?.nat
                                      : "High Card"}
                                  </div>
                                  <span className="casino-odds-point">
                                    <span>
                                      {reqRes?.data?.data?.t2[6]?.rate}{" "}
                                    </span>
                                    <div
                                      className="text-center text-success"
                                      style={{ minHeight: "20px" }}
                                    >
                                      {thirtyTwoCardStack.map(
                                        (ele, index) =>
                                          ele.number === 7 && (
                                            <span
                                              className={`${
                                                ele.number === 7 &&
                                                ele.amount >= 0
                                                  ? "success-1"
                                                  : "zero"
                                              }`}
                                              key={index}
                                            >
                                              {ele.number === 7 && ele.amount}
                                            </span>
                                          )
                                      )}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            )}

                            {/* </span> */}
                          </div>
                          {/* <div className="casinooddsbox ">
                            <div className=" casino6poker-item ">
                              <div className="casino-nation-name d-flex">
                                {reqRes?.data?.data?.t2[6]?.nat != null
                                  ? reqRes?.data?.data?.t2[6]?.nat
                                  : "High Card"}

                           
                              </div>
                              <div className="">
                                <span className="casino-odds-point ">
                                  {reqRes?.data?.data?.t2[6]?.gstatus !=
                                    "1" && (
                                    <div className="suspended__13">
                                      <i className="fa fa-lock"></i>
                                    </div>
                                  )}
                                  <span
                                    onClick={() => {
                                      handleModal(
                                        reqRes?.data?.data?.t2[6]?.rate,
                                        reqRes?.data?.data?.t2[6]?.nat,
                                        7
                                      );
                                      setBetIndex(
                                        reqRes?.data?.data?.t2[6]?.sid
                                      );
                                    }}
                                  >
                                    {reqRes?.data?.data?.t2[6]?.rate}{" "}
                                  </span>
                                </span>
                              </div>
                            </div>
                            {/* <div className="poker6-odds ">
                              <p className=""> 0 </p>
                              <span className="">
                                <b className="">Min</b>: 100
                                <b className="">Max</b>: 100000
                              </span>
                            </div> */}
                          {/* </div> */}
                        </div>
                        <div className="col-12 col-sm-6 col-md-4  poker-6player">
                          <div className="">
                            {reqRes?.data?.data?.t2[7]?.gstatus != "1" ? (
                              <span className="casino-odds-point suspended-box">
                                <div className="casinooddsbox ">
                                  <div className=" casino6poker-item ">
                                    <div className="casino-nation-name d-flex">
                                      {" "}
                                      {reqRes?.data?.data?.t2[7]?.nat != null
                                        ? reqRes?.data?.data?.t2[7]?.nat
                                        : "Pair"}
                                    </div>
                                    <div className="">
                                      <span className="casino-odds-point">
                                        <span>
                                          {reqRes?.data?.data?.t2[7]?.rate}{" "}
                                        </span>
                                        <div
                                          className="text-center text-success"
                                          style={{ minHeight: "16px" }}
                                        >
                                          {" "}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            ) : (
                              <div
                                className="casinooddsbox"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[7]?.rate,
                                    reqRes?.data?.data?.t2[7]?.nat,
                                    8
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[7]?.sid);
                                }}
                              >
                                <div className="casino6poker-item ">
                                  <div className="casino-nation-name d-flex">
                                    {" "}
                                    {reqRes?.data?.data?.t2[7]?.nat != null
                                      ? reqRes?.data?.data?.t2[7]?.nat
                                      : "Pair"}
                                  </div>
                                  <span className="casino-odds-point">
                                    <span>
                                      {reqRes?.data?.data?.t2[7]?.rate}{" "}
                                    </span>
                                    <div
                                      className="text-center text-success"
                                      style={{ minHeight: "20px" }}
                                    >
                                      {thirtyTwoCardStack.map(
                                        (ele, index) =>
                                          ele.number === 8 && (
                                            <span
                                              className={`${
                                                ele.number === 8 &&
                                                ele.amount >= 0
                                                  ? "success-1"
                                                  : "zero"
                                              }`}
                                              key={index}
                                            >
                                              {ele.number === 8 && ele.amount}
                                            </span>
                                          )
                                      )}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            )}

                            {/* </span> */}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4  poker-6player">
                          <div className="">
                            {reqRes?.data?.data?.t2[8]?.gstatus != "1" ? (
                              <span className="casino-odds-point suspended-box">
                                <div className="casinooddsbox ">
                                  <div className=" casino6poker-item ">
                                    <div className="casino-nation-name d-flex">
                                      {" "}
                                      {reqRes?.data?.data?.t2[8]?.nat != null
                                        ? reqRes?.data?.data?.t2[8]?.nat
                                        : "Two Pair"}
                                    </div>
                                    <div className="">
                                      <span className="casino-odds-point">
                                        <span>
                                          {reqRes?.data?.data?.t2[8]?.rate}{" "}
                                        </span>
                                        <div
                                          className="text-center text-success"
                                          style={{ minHeight: "16px" }}
                                        >
                                          {" "}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            ) : (
                              <div
                                className="casinooddsbox"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[8]?.rate,
                                    reqRes?.data?.data?.t2[8]?.nat,
                                    9
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[8]?.sid);
                                }}
                              >
                                <div className="casino6poker-item ">
                                  <div className="casino-nation-name d-flex">
                                    {" "}
                                    {reqRes?.data?.data?.t2[8]?.nat != null
                                      ? reqRes?.data?.data?.t2[8]?.nat
                                      : "Two Pair"}
                                  </div>
                                  <span className="casino-odds-point">
                                    <span>
                                      {reqRes?.data?.data?.t2[8]?.rate}{" "}
                                    </span>
                                    <div
                                      className="text-center text-success"
                                      style={{ minHeight: "16px" }}
                                    >
                                      {thirtyTwoCardStack.map(
                                        (ele, index) =>
                                          ele.number === 9 && (
                                            <span
                                              className={`${
                                                ele.number === 9 &&
                                                ele.amount >= 0
                                                  ? "success-1"
                                                  : "zero"
                                              }`}
                                              key={index}
                                            >
                                              {ele.number === 9 && ele.amount}
                                            </span>
                                          )
                                      )}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            )}

                            {/* </span> */}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4  poker-6player">
                          <div className="">
                            {reqRes?.data?.data?.t2[9]?.gstatus != "1" ? (
                              <span className="casino-odds-point suspended-box">
                                <div className="casinooddsbox ">
                                  <div className=" casino6poker-item ">
                                    <div className="casino-nation-name d-flex">
                                      {" "}
                                      {reqRes?.data?.data?.t2[9]?.nat != null
                                        ? reqRes?.data?.data?.t2[9]?.nat
                                        : "Three of Kind"}
                                    </div>
                                    <div className="">
                                      <span className="casino-odds-point">
                                        <span>
                                          {reqRes?.data?.data?.t2[9]?.rate}{" "}
                                        </span>
                                        <div
                                          className="text-center text-success"
                                          style={{ minHeight: "16px" }}
                                        >
                                          {" "}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            ) : (
                              <div
                                className="casinooddsbox"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[9]?.rate,
                                    reqRes?.data?.data?.t2[9]?.nat,
                                    10
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[9]?.sid);
                                }}
                              >
                                <div className="casino6poker-item ">
                                  <div className="casino-nation-name d-flex">
                                    {" "}
                                    {reqRes?.data?.data?.t2[9]?.nat != null
                                      ? reqRes?.data?.data?.t2[9]?.nat
                                      : "Three of Kind"}
                                  </div>
                                  <span className="casino-odds-point">
                                    <span>
                                      {reqRes?.data?.data?.t2[9]?.rate}{" "}
                                    </span>
                                    <div
                                      className="text-center text-success"
                                      style={{ minHeight: "20px" }}
                                    >
                                      {thirtyTwoCardStack.map(
                                        (ele, index) =>
                                          ele.number === 10 && (
                                            <span
                                              className={`${
                                                ele.number === 10 &&
                                                ele.amount >= 0
                                                  ? "success-1"
                                                  : "zero"
                                              }`}
                                              key={index}
                                            >
                                              {ele.number === 10 && ele.amount}
                                            </span>
                                          )
                                      )}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            )}

                            {/* </span> */}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4  poker-6player">
                          <div className="">
                            {reqRes?.data?.data?.t2[10]?.gstatus != "1" ? (
                              <span className="casino-odds-point suspended-box">
                                <div className="casinooddsbox ">
                                  <div className=" casino6poker-item ">
                                    <div className="casino-nation-name d-flex">
                                      {" "}
                                      {reqRes?.data?.data?.t2[10]?.nat != null
                                        ? reqRes?.data?.data?.t2[10]?.nat
                                        : "Straight"}
                                    </div>
                                    <div className="">
                                      <span className="casino-odds-point">
                                        <span>
                                          {reqRes?.data?.data?.t2[10]?.rate}{" "}
                                        </span>
                                        <div
                                          className="text-center text-success"
                                          style={{ minHeight: "16px" }}
                                        >
                                          {" "}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            ) : (
                              <div
                                className="casinooddsbox"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[10]?.rate,
                                    reqRes?.data?.data?.t2[10]?.nat,
                                    11
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[10]?.sid);
                                }}
                              >
                                <div className="casino6poker-item ">
                                  <div className="casino-nation-name d-flex">
                                    {" "}
                                    {reqRes?.data?.data?.t2[10]?.nat != null
                                      ? reqRes?.data?.data?.t2[10]?.nat
                                      : "Straight"}
                                  </div>
                                  <span className="casino-odds-point">
                                    <span>
                                      {reqRes?.data?.data?.t2[10]?.rate}{" "}
                                    </span>
                                    <div
                                      className="text-center text-success"
                                      style={{ minHeight: "20px" }}
                                    >
                                      {thirtyTwoCardStack.map(
                                        (ele, index) =>
                                          ele.number === 11 && (
                                            <span
                                              className={`${
                                                ele.number === 11 &&
                                                ele.amount >= 0
                                                  ? "success-1"
                                                  : "zero"
                                              }`}
                                              key={index}
                                            >
                                              {ele.number === 11 && ele.amount}
                                            </span>
                                          )
                                      )}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            )}

                            {/* </span> */}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4  poker-6player">
                          <div className="">
                            {reqRes?.data?.data?.t2[11]?.gstatus != "1" ? (
                              <span className="casino-odds-point suspended-box">
                                <div className="casinooddsbox ">
                                  <div className=" casino6poker-item ">
                                    <div className="casino-nation-name d-flex">
                                      {" "}
                                      {reqRes?.data?.data?.t2[11]?.nat != null
                                        ? reqRes?.data?.data?.t2[11]?.nat
                                        : "Flush"}
                                    </div>
                                    <div className="">
                                      <span className="casino-odds-point">
                                        <span>
                                          {reqRes?.data?.data?.t2[11]?.rate}{" "}
                                        </span>
                                        <div
                                          className="text-center text-success"
                                          style={{ minHeight: "16px" }}
                                        >
                                          {" "}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            ) : (
                              <div
                                className="casinooddsbox"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[11]?.rate,
                                    reqRes?.data?.data?.t2[11]?.nat,
                                    12
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[11]?.sid);
                                }}
                              >
                                <div className="casino6poker-item ">
                                  <div className="casino-nation-name d-flex">
                                    {" "}
                                    {reqRes?.data?.data?.t2[11]?.nat != null
                                      ? reqRes?.data?.data?.t2[11]?.nat
                                      : "Flush"}
                                  </div>
                                  <span className="casino-odds-point">
                                    <span>
                                      {reqRes?.data?.data?.t2[11]?.rate}{" "}
                                    </span>
                                    <div
                                      className="text-center text-success"
                                      style={{ minHeight: "20px" }}
                                    >
                                      {thirtyTwoCardStack.map(
                                        (ele, index) =>
                                          ele.number === 12 && (
                                            <span
                                              className={`${
                                                ele.number === 12 &&
                                                ele.amount >= 0
                                                  ? "success-1"
                                                  : "zero"
                                              }`}
                                              key={index}
                                            >
                                              {ele.number === 12 && ele.amount}
                                            </span>
                                          )
                                      )}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            )}

                            {/* </span> */}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4  poker-6player">
                          <div className="">
                            {reqRes?.data?.data?.t2[12]?.gstatus != "1" ? (
                              <span className="casino-odds-point suspended-box">
                                <div className="casinooddsbox ">
                                  <div className=" casino6poker-item ">
                                    <div className="casino-nation-name d-flex">
                                      {" "}
                                      {reqRes?.data?.data?.t2[12]?.nat != null
                                        ? reqRes?.data?.data?.t2[12]?.nat
                                        : "Full House"}
                                    </div>
                                    <div className="">
                                      <span className="casino-odds-point">
                                        <span>
                                          {reqRes?.data?.data?.t2[12]?.rate}{" "}
                                        </span>
                                        <div
                                          className="text-center text-success"
                                          style={{ minHeight: "16px" }}
                                        >
                                          {" "}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            ) : (
                              <div
                                className="casinooddsbox"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[12]?.rate,
                                    reqRes?.data?.data?.t2[12]?.nat,
                                    13
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[12]?.sid);
                                }}
                              >
                                <div className="casino6poker-item ">
                                  <div className="casino-nation-name d-flex">
                                    {" "}
                                    {reqRes?.data?.data?.t2[12]?.nat != null
                                      ? reqRes?.data?.data?.t2[12]?.nat
                                      : "Full House"}
                                  </div>
                                  <span className="casino-odds-point">
                                    <span>
                                      {reqRes?.data?.data?.t2[12]?.rate}{" "}
                                    </span>
                                    <div
                                      className="text-center text-success"
                                      style={{ minHeight: "20px" }}
                                    >
                                      {thirtyTwoCardStack.map(
                                        (ele, index) =>
                                          ele.number === 13 && (
                                            <span
                                              className={`${
                                                ele.number === 13 &&
                                                ele.amount >= 0
                                                  ? "success-1"
                                                  : "zero"
                                              }`}
                                              key={index}
                                            >
                                              {ele.number === 13 && ele.amount}
                                            </span>
                                          )
                                      )}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            )}

                            {/* </span> */}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4  poker-6player">
                          <div className="">
                            {reqRes?.data?.data?.t2[13]?.gstatus != "1" ? (
                              <span className="casino-odds-point suspended-box">
                                <div className="casinooddsbox ">
                                  <div className=" casino6poker-item ">
                                    <div className="casino-nation-name d-flex">
                                      {" "}
                                      {reqRes?.data?.data?.t2[13]?.nat != null
                                        ? reqRes?.data?.data?.t2[13]?.nat
                                        : "Four of a Kind"}
                                    </div>
                                    <div className="">
                                      <span className="casino-odds-point">
                                        <span>
                                          {reqRes?.data?.data?.t2[13]?.rate}{" "}
                                        </span>
                                        <div
                                          className="text-center text-success"
                                          style={{ minHeight: "16px" }}
                                        >
                                          {" "}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            ) : (
                              <div
                                className="casinooddsbox"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[13]?.rate,
                                    reqRes?.data?.data?.t2[13]?.nat,
                                    14
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[13]?.sid);
                                }}
                              >
                                <div className="casino6poker-item ">
                                  <div className="casino-nation-name d-flex">
                                    {" "}
                                    {reqRes?.data?.data?.t2[13]?.nat != null
                                      ? reqRes?.data?.data?.t2[13]?.nat
                                      : "Four of a Kind"}
                                  </div>
                                  <span className="casino-odds-point">
                                    <span>
                                      {reqRes?.data?.data?.t2[13]?.rate}{" "}
                                    </span>
                                    <div
                                      className="text-center text-success"
                                      style={{ minHeight: "20px" }}
                                    >
                                      {thirtyTwoCardStack.map(
                                        (ele, index) =>
                                          ele.number === 14 && (
                                            <span
                                              className={`${
                                                ele.number === 14 &&
                                                ele.amount >= 0
                                                  ? "success-1"
                                                  : "zero"
                                              }`}
                                              key={index}
                                            >
                                              {ele.number === 14 && ele.amount}
                                            </span>
                                          )
                                      )}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            )}

                            {/* </span> */}
                          </div>
                          {/* <div className="casinooddsbox ">
                            <div className=" casino6poker-item ">
                              <div className="casino-nation-name d-flex">
                                {reqRes?.data?.data?.t2[13]?.nat != null
                                  ? reqRes?.data?.data?.t2[13]?.nat
                                  : "Four of a Kind"}
                              </div>
                              <div className="">
                                <span className="casino-odds-point ">
                                  {reqRes?.data?.data?.t2[13]?.gstatus !=
                                    "1" && (
                                    <div className="suspended__13">
                                      <i className="fa fa-lock"></i>
                                    </div>
                                  )}
                                  <span
                                    onClick={() => {
                                      handleModal(
                                        reqRes?.data?.data?.t2[13]?.rate,
                                        reqRes?.data?.data?.t2[13]?.nat,
                                        14
                                      );
                                      setBetIndex(
                                        reqRes?.data?.data?.t2[13]?.sid
                                      );
                                    }}
                                  >
                                    {reqRes?.data?.data?.t2[13]?.rate}{" "}
                                  </span>
                                </span>
                              </div>
                            </div>
                            {/* <div className="poker6-odds ">
                              <p className=""> 0 </p>
                              <span className="">
                                <b className="">Min</b>: 100
                                <b className="">Max</b>: 100000
                              </span>
                            </div> */}
                          {/* </div> */}
                        </div>
                        <div className="col-12 col-sm-6 col-md-4  poker-6player">
                          <div className="">
                            {reqRes?.data?.data?.t2[14]?.gstatus != "1" ? (
                              <span className="casino-odds-point suspended-box">
                                <div className="casinooddsbox ">
                                  <div className=" casino6poker-item ">
                                    <div className="casino-nation-name d-flex">
                                      {" "}
                                      {reqRes?.data?.data?.t2[14]?.nat != null
                                        ? reqRes?.data?.data?.t2[14]?.nat
                                        : "Straight Flush"}
                                    </div>
                                    <div className="">
                                      <span className="casino-odds-point">
                                        <span>
                                          {reqRes?.data?.data?.t2[14]?.rate}{" "}
                                        </span>
                                        <div
                                          className="text-center text-success"
                                          style={{ minHeight: "16px" }}
                                        >
                                          {" "}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            ) : (
                              <div
                                className="casinooddsbox"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[14]?.rate,
                                    reqRes?.data?.data?.t2[14]?.nat,
                                    15
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[14]?.sid);
                                }}
                              >
                                <div className="casino6poker-item ">
                                  <div className="casino-nation-name d-flex">
                                    {" "}
                                    {reqRes?.data?.data?.t2[14]?.nat != null
                                      ? reqRes?.data?.data?.t2[14]?.nat
                                      : "Straight Flush"}
                                  </div>
                                  <span className="casino-odds-point">
                                    <span>
                                      {reqRes?.data?.data?.t2[14]?.rate}{" "}
                                    </span>
                                    <div
                                      className="text-center text-success"
                                      style={{ minHeight: "20px" }}
                                    >
                                      {thirtyTwoCardStack.map(
                                        (ele, index) =>
                                          ele.number === 15 && (
                                            <span
                                              className={`${
                                                ele.number === 15 &&
                                                ele.amount >= 0
                                                  ? "success-1"
                                                  : "zero"
                                              }`}
                                              key={index}
                                            >
                                              {ele.number === 15 && ele.amount}
                                            </span>
                                          )
                                      )}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            )}

                            {/* </span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </CustomTabPanel>
                </Box>
              </div>
              <div className="Results-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Last 10 Results</p>
                  </div>
                </div>
                <div className="card rst-card py-1 d-flex">
                  <div className="rst-row d-flex">
                    {results?.data?.data?.map((result, index) => {
                      const item = reqRes?.data?.data?.t2.find(
                        (item) => item.sid === result?.result
                      );

                      return (
                        <div
                          onClick={() => {
                            setMidRound(result.mid);
                            CasinoGameResult_API(result.mid);
                            setShowresultModal(true);
                            setTrophy(resultMapping[result.result]);
                          }}
                          className="rst-btn ms-1"
                          key={index}
                        >
                          {item ? <>{resultMapping[result.result]}</> : <>T</>}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* <<<<<<<---------My-Bets-table-------------->>>>>>>>>> */}
              <div className="My-Bets-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Open Bets</p>
                  </div>
                </div>
                {HistoryTable}
              </div>
            </div>
          </div>

          <RightSidebar />
          {showModal && (
            <Modal
              id={id}
              name={name}
              betDetails={betDetails}
              setbetDetails={setbetDetails}
              setShowModal={setShowModal}
              realTime={realTime}
              setRealTimeData={setRealTimeData}
            />
          )}
          {showresultModal && <ResultsModal />}
          {/* {showDataModal && <DataModal />} */}
        </div>
      </div>
    </>
  );
}
