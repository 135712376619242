import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import { useDispatch, useSelector } from "react-redux";
import a from "../../Media/10SS.png";
import b from "../../Media/3SS.png";
import { MdCancel } from "react-icons/md";
import { FaTrophy } from "react-icons/fa";
import "./OneDayDragonTiger.css";
import heart from "../../Media/heart-2.png";
import diamond from "../../Media/diamond-2.png";
import spade from "../../Media/spade-2.png";
import club from "../../Media/club-2.png";
import Modal from "../TeenPatti/Modal";
import { API } from "../../API/API";
import { useParams } from "react-router-dom";
import { fetchThirtyTwoCardStack } from "../../Redux/Slices/thirtyTwoCardGameSlice";
import { io } from "socket.io-client";
import Rules_Modal from "../Rules_Modal";
import { card52War } from "../../Utility/52CardImages.js";
import cardBlank from "../../Media/DTL2020/backcard.webp";

export default function OneDayDragonTiger() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const [showresultModal, setShowresultModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [betDetails, setbetDetails] = useState({});
  const [showDataModal, setShowdataModal] = useState(false);
  const [rulesModal, setRulesModal] = useState(false);
  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const [trophy, setTrophy] = useState("");
  const [realTime, setRealTimeData] = useState(false);
  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      console.log("CasinoGameResult dragon:", response.data.data[0]);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"></div>
        <div className="results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound}</div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
              }}
            />
          </div>
          <div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div ">
                <div className="text-center">
                  {" "}
                  Dragon
                  {trophy == "D" && <FaTrophy className="trophy-icon" />}
                </div>
                <div className="d-flex">
                  {casinoGameResult[0] && (
                    <div
                      className="gamecard-div mx-1"
                      key={casinoGameResult[0].id}>
                      <img
                        src={casinoGameResult[0].image}
                        className="img-fluid"
                        alt={`Player A Card 0`}
                      />
                    </div>
                  )}
                  {/* <div className="gamecard-div mx-1">
                    <img src={b} className="img-fluid" alt="a" />
                  </div>
                  <div className="gamecard-div mx-1">
                    <img src={a} className="img-fluid" alt="a" />
                  </div>
                  <div className="gamecard-div mx-1">
                    <img src={b} className="img-fluid" alt="a" />
                  </div> */}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div  ">
                <div className="d-flex justify-content-between">
                  {/* <div className="text-center">Player 9: 18</div>
                  <FaTrophy className="trophy-icon" /> */}
                  <div className="text-center">Tiger</div>
                  {trophy == "T" && <FaTrophy className="trophy-icon" />}
                </div>

                <div className="d-flex">
                  {casinoGameResult[1] && (
                    <div
                      className="gamecard-div mx-1"
                      key={casinoGameResult[1].id}>
                      <img
                        src={casinoGameResult[1].image}
                        className="img-fluid"
                        alt={`Player A Card 0`}
                      />
                    </div>
                  )}
                  {/* <div className="gamecard-div mx-1">
                    <img src={b} className="img-fluid" alt="a" />
                  </div>
                  <div className="gamecard-div mx-1">
                    <img src={a} className="img-fluid" alt="a" />
                  </div>
                  <div className="gamecard-div mx-1">
                    <img src={b} className="img-fluid" alt="a" />
                  </div> */}
                </div>
              </div>
            </div>
            {/* <div className="row mt-2 justify-content-center">
              <div className="col-md-6">
                <div className="casino-result-desc">
                  <div className="casino-result-desc-item">
                    <div>Winner</div>
                    <div>K Diamond</div>
                  </div>
                  <div className="casino-result-desc-item">
                    <div>Points</div>
                    <div>106</div>
                  </div>
                  <div className="casino-result-desc-item">
                    <div>Cards</div>
                    <div>16</div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </>
    );
  };

  const SOCKET_URL = "https://gamecoin-socket.nakshtech.info/";

  useEffect(() => {
    // console.log("Odd socket before");
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("TeenPatti socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        console.log("parsed", parsedData[0]);
        // console.log("parsed_result", parsedData[0].resultUrlResponse);
        setResults(parsedData[0].resultUrlResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  async function ResultScheduler_API() {
    try {
      let res = await API.post("/Schulder_7CareGameLive", {
        gameid: id,
        username: uid
      },
        {
          headers: {
            Authorization: token,
          },
        });
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  const [history, setHistory] = useState([]);
  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);
    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id]);

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nat: nat,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  // function checkValid() {
  //   if (showModal && realTime) {
  //     let filteredData = reqRes?.data?.data?.t2.filter(
  //       (item) => item.nat === betDetails.nat
  //     );
  //     console.log("filter", filteredData, betDetails);
  //     filteredData[0]?.gstatus !== "0" &&
  //     betDetails?.nat === filteredData[0]?.nat &&
  //     betDetails?.multipler === filteredData[0].rate
  //       ? setRealTimeData(true)
  //       : setRealTimeData(false);
  //   }
  // }

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.nat === betDetails.nat
      );
      console.log("filter", filteredData, betDetails?.multipler);
      filteredData[0]?.gstatus === "ACTIVE" &&
      (betDetails?.multipler === filteredData[0].l1 ||
        betDetails?.multipler === filteredData[0].b1)
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  const findCardImage = (cardId) => {
    const card = card52War.find((card) => card.id === cardId);
    return card ? card.src : null;
  };

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {/* {loading ? (
              <tr>
                <td colSpan="6">Loading ...</td>
              </tr>
            ) : */}
            {history.length > 0 ? (
              history.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  return (
    <>
      <div className="col-lg-10 ">
        <div className="row home mainhomeDev">
          <div className="col-lg-8 p-1">
            <div className="teenpatti-game-div pt-2 p-2">
              <div className="teenpatti-game-h d-flex justify-content-between  ">
                <div className=" px-2">
                  <p className=" mt-1 ">{name}</p>
                </div>
                <div>
                  <p className="mt-1 px-2">
                    Round ID: {reqRes?.data?.data?.t1[0]?.mid || 0.0} | Left
                    Time: {reqRes?.data?.data?.t1[0]?.autotime || 0} |{" "}
                    <span onClick={() => setRulesModal(true)}>Rules</span>
                  </p>
                </div>
              </div>
              <div className="card  teenpatti-game-card p-1 mt-1">
                <div className="row">
                  <div className="col-10">
                    <div className="" style={{ height: "170%", width: "120%" }}>
                      <iframe
                        height="100%"
                        width="100%"
                        src={`https://battlemoney.live/iframe/${id}`}
                        title=""></iframe>
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-end">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="timer ">
                    <div
                      className="container"
                      style={{ color: "white",marginLeft:"-1px",  marginTop:'-150px'}}
                    >
                      {/* <div className="timer_container position-absolute"  > */}
                      <span></span>
                      <div>
                        {["C1","C2"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId) || cardBlank}
                                alt={cardId}
                                style={{ width: "30px", height: "auto", marginRight:'10px' }}
                              />
                            </span>   
                          ) : null;
                        })}
                      </div>
                      {/* <span>
                      <span>Player B</span>
                      <div>
                        {["C3", "C4"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId)}
                                alt={cardId}
                                style={{ width: "20px", height: "auto" }}
                              />
                            </span>
                          ) : null;
                        })}
                      </div>
                      </span>  */}
                      {/* Board */}

                      {/* <span>
                      <span>Board</span>
                      <div>
                        {["C5", "C6","C7","C8"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId)}
                                alt={cardId}
                                style={{ width: "20px", height: "auto" }}
                              />
                            </span>
                          ) : null;
                        })}
                      </div>
                      </span>  */}


                     

                      {/* </div> */}
                    </div>
                  </div>
                  <div
                    className="timer "
                    style={{ marginLeft: "550px", marginTop: "20px" }}>
                    <div className="container">
                    <div
                      className="timer_container position-absolute"
                      style={{ right: "36px", bottom: "15px" }}
                    >
                      <button
                        class="btn btn-secondary"
                        style={{ cursor: "default", background: "#6f42c1" }}
                      >
                        <span>
                          {" "}
                          {reqRes?.data?.data?.t1[0]?.autotime || 0}{" "}
                        </span>
                      </button>{" "} 
                    </div> 
                    </div>
                  </div>
                </div>
              </div>

              {/* <-------------------------------Back-lay-table----------> */}
              <div className="onedaydragionTigersection p-1">
                <div className="casino-detail">
                  <div className="casino-table">
                    <div className="casino-table-box">
                      <div className="casino-table-left-box">
                        <div className="casino-table-header">
                          <div className="casino-nation-detail"></div>
                          <div className="casino-odds-box back">Back</div>
                          <div className="casino-odds-box lay">Lay</div>
                        </div>

                        <div className="casino-table-body">
                          <div className="casino-table-row ">
                            <div className="casino-nation-detail">
                              <div className="casino-nation-name">
                                {reqRes?.data?.data?.t2[0]?.nat}{" "}
                                {thirtyTwoCardStack.map(
                                  (ele, index) =>
                                    ele.number === 1 && (
                                      <span
                                        className={` ${
                                          ele.number == 1 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                        key={index}>
                                        {ele.number == 1 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </div>
                            {reqRes?.data?.data?.t2[0]?.gstatus ===
                              "SUSPENDED" ||
                            reqRes?.data?.data?.t2[0]?.gstatus === "CLOSED" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds"></span>
                              </div>
                            ) : (
                              <span
                                onClick={() => {
                                  setShowdataModal(true);
                                  handleModal(
                                    reqRes?.data?.data?.t2[0]?.b1,
                                    reqRes?.data?.data?.t2[0]?.nat,
                                    1
                                  );
                                }}
                                className="casino-odds-box back">
                                {reqRes?.data?.data?.t2[0]?.b1}
                              </span>
                            )}

                            {/* <div className="casino-odds-box back suspended-box">
                              <span className="casino-odds">0</span>
                            </div> */}
                            {/* <div className="casino-odds-box lay suspended-box">
                              <span className="casino-odds">0</span>
                            </div> */}
                            {reqRes?.data?.data?.t2[0]?.gstatus ===
                              "SUSPENDED" ||
                            reqRes?.data?.data?.t2[0]?.gstatus === "CLOSED" ? (
                              <div className="casino-odds-box lay suspended-box">
                                <span className="casino-odds"></span>
                              </div>
                            ) : (
                              <span
                                onClick={() => {
                                  setShowdataModal(true);
                                  handleModal(
                                    reqRes?.data?.data?.t2[0]?.l1,
                                    reqRes?.data?.data?.t2[0]?.nat,
                                    3
                                  );
                                }}
                                className="casino-odds-box lay">
                                {reqRes?.data?.data?.t2[0]?.l1}
                              </span>
                            )}
                          </div>
                          <div className="casino-table-row">
                            <div className="casino-nation-detail">
                              <div className="casino-nation-name">
                                {" "}
                                {reqRes?.data?.data?.t2[1]?.nat}{" "}
                                {thirtyTwoCardStack.map(
                                  (ele, index) =>
                                    ele.number === 2 && (
                                      <span
                                        className={` ${
                                          ele.number == 2 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                        key={index}>
                                        {ele.number == 2 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </div>

                            {reqRes?.data?.data?.t2[1]?.gstatus ===
                              "SUSPENDED" ||
                            reqRes?.data?.data?.t2[1]?.gstatus === "CLOSED" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds"></span>
                              </div>
                            ) : (
                              <span
                                onClick={() => {
                                  setShowdataModal(true);
                                  handleModal(
                                    reqRes?.data?.data?.t2[1]?.b1,
                                    reqRes?.data?.data?.t2[1]?.nat,
                                    2
                                  );
                                }}
                                className="casino-odds-box back">
                                {reqRes?.data?.data?.t2[1]?.b1}
                              </span>
                            )}
                            {reqRes?.data?.data?.t2[1]?.gstatus ===
                              "SUSPENDED" ||
                            reqRes?.data?.data?.t2[1]?.gstatus === "CLOSED" ? (
                              <div className="casino-odds-box lay suspended-box">
                                <span className="casino-odds"></span>
                              </div>
                            ) : (
                              <span
                                onClick={() => {
                                  setShowdataModal(true);
                                  handleModal(
                                    reqRes?.data?.data?.t2[1]?.l1,
                                    reqRes?.data?.data?.t2[1]?.nat,
                                    4
                                  );
                                }}
                                className="casino-odds-box lay">
                                {reqRes?.data?.data?.t2[1]?.l1}
                              </span>
                            )}
                            {/* <div className="casino-odds-box back suspended-box">
                              <span className="casino-odds">0</span>
                            </div> */}
                            {/* <div className="casino-odds-box lay suspended-box">
                              <span className="casino-odds">0</span>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="casino-table-right-box dtpair">
                        <div className="casino-odds text-center">
                          {reqRes?.data?.data?.t2[2]?.b1}
                        </div>
                        {reqRes?.data?.data?.t2[2]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[2]?.gstatus === "CLOSED" ? (
                          <div className="casino-odds-box back casino-odds-box-theme suspended-box">
                            <span className="casino-odds">Pair</span>
                          </div>
                        ) : (
                          <div className="casino-odds-box back casino-odds-box-theme">
                            <span
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[2]?.b1,
                                  reqRes?.data?.data?.t2[2]?.nat,
                                  5
                                );
                              }}
                              className="casino-odds">
                              Pair
                            </span>
                          </div>
                        )}
                        {thirtyTwoCardStack.map(
                          (ele, index) =>
                            ele.number === 3 && (
                              <span
                                className={` ${
                                  ele.number == 3 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}
                                key={index}>
                                {ele.number == 3 && ele.amount}
                              </span>
                            )
                        )}

                        {/* <div className="casino-odds text-center">0</div>
                        <div className="casino-odds-box back casino-odds-box-theme suspended-box">
                          <span className="casino-odds">Pair</span>
                        </div> */}
                      </div>
                    </div>
                    <div className="casino-table-box mt-3">
                      <div className="casino-table-left-box">
                        <div className="casino-table-header">
                          <div className="casino-nation-detail"></div>
                          <div className="casino-odds-box back">Even</div>
                          <div className="casino-odds-box back">Odd</div>
                        </div>
                        <div className="casino-table-body">
                          <div className="casino-table-row ">
                            <div className="casino-nation-detail">
                              <div className="casino-nation-name">Dragon </div>
                            </div>
                            {reqRes?.data?.data?.t2[3]?.gstatus ===
                              "SUSPENDED" ||
                            reqRes?.data?.data?.t2[3]?.gstatus === "CLOSED" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds"></span>
                              </div>
                            ) : (
                              <span
                                onClick={() => {
                                  setShowdataModal(true);
                                  handleModal(
                                    reqRes?.data?.data?.t2[3]?.b1,
                                    reqRes?.data?.data?.t2[3]?.nat,
                                    6
                                  );
                                }}
                                className="casino-odds-box back">
                                {reqRes?.data?.data?.t2[3]?.b1}
                                <div>
                                  {thirtyTwoCardStack.map(
                                    (ele, index) =>
                                      ele.number === 4 && (
                                        <span
                                          className={` ${
                                            ele.number == 4 && ele.amount >= 0
                                              ? "success-1"
                                              : "zero"
                                          }`}
                                          key={index}>
                                          {ele.number == 4 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </div>
                              </span>
                            )}
                            {reqRes?.data?.data?.t2[4]?.gstatus ===
                              "SUSPENDED" ||
                            reqRes?.data?.data?.t2[4]?.gstatus === "CLOSED" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds"></span>
                              </div>
                            ) : (
                              <span
                                onClick={() => {
                                  setShowdataModal(true);
                                  handleModal(
                                    reqRes?.data?.data?.t2[4]?.b1,
                                    reqRes?.data?.data?.t2[4]?.nat,
                                    7
                                  );
                                }}
                                className="casino-odds-box back">
                                {reqRes?.data?.data?.t2[4]?.b1}
                                <div>
                                  {thirtyTwoCardStack.map(
                                    (ele, index) =>
                                      ele.number === 5 && (
                                        <span
                                          className={` ${
                                            ele.number == 5 && ele.amount >= 0
                                              ? "success-1"
                                              : "zero"
                                          }`}
                                          key={index}>
                                          {ele.number == 5 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </div>
                              </span>
                            )}
                            {/* <div className="casino-odds-box back suspended-box">
                              <span className="casino-odds">0</span>
                            </div> */}
                            {/* <div className="casino-odds-box back suspended-box">
                              <span className="casino-odds">0</span>
                            </div> */}
                          </div>
                          <div className="casino-table-row">
                            <div className="casino-nation-detail">
                              <div className="casino-nation-name">Tiger</div>
                            </div>
                            {reqRes?.data?.data?.t2[11]?.gstatus ===
                              "SUSPENDED" ||
                            reqRes?.data?.data?.t2[11]?.gstatus === "CLOSED" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds"></span>
                              </div>
                            ) : (
                              <span
                                onClick={() => {
                                  setShowdataModal(true);
                                  handleModal(
                                    reqRes?.data?.data?.t2[11]?.b1,
                                    reqRes?.data?.data?.t2[11]?.nat,
                                    8
                                  );
                                }}
                                className="casino-odds-box back">
                                {reqRes?.data?.data?.t2[11]?.b1}
                                <div>
                                  {thirtyTwoCardStack.map(
                                    (ele, index) =>
                                      ele.number === 6 && (
                                        <span
                                          className={` ${
                                            ele.number == 6 && ele.amount >= 0
                                              ? "success-1"
                                              : "zero"
                                          }`}
                                          key={index}>
                                          {ele.number == 6 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </div>
                              </span>
                            )}
                            {reqRes?.data?.data?.t2[12]?.gstatus ===
                              "SUSPENDED" ||
                            reqRes?.data?.data?.t2[12]?.gstatus === "CLOSED" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds"></span>
                              </div>
                            ) : (
                              <span
                                onClick={() => {
                                  setShowdataModal(true);
                                  handleModal(
                                    reqRes?.data?.data?.t2[12]?.b1,
                                    reqRes?.data?.data?.t2[12]?.nat,
                                    9
                                  );
                                }}
                                className="casino-odds-box back">
                                {reqRes?.data?.data?.t2[12]?.b1}
                                <div>
                                  {thirtyTwoCardStack.map(
                                    (ele, index) =>
                                      ele.number === 7 && (
                                        <span
                                          className={` ${
                                            ele.number == 7 && ele.amount >= 0
                                              ? "success-1"
                                              : "zero"
                                          }`}
                                          key={index}>
                                          {ele.number == 7 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </div>
                              </span>
                            )}
                            {/* <div className="casino-odds-box back suspended-box">
                              <span className="casino-odds">0</span>
                            </div>
                            <div className="casino-odds-box back suspended-box">
                              <span className="casino-odds">0</span>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="casino-table-right-box dtredblack">
                        <div className="casino-table-header">
                          <div className="casino-nation-detail"></div>
                          <div className="casino-odds-box back minred">
                            <span>Red</span>
                            <span className="card-icon ms-1">
                              <span className="card-red ">
                                {" "}
                                <img
                                  className="w-100 h-100"
                                  src={heart}
                                  alt="1"
                                />
                              </span>
                            </span>
                            <span className="card-icon ms-1">
                              <span className="card-red ">
                                {" "}
                                <img
                                  className="w-100 h-100"
                                  src={diamond}
                                  alt="2"
                                />
                              </span>
                            </span>
                          </div>
                          <div className="casino-odds-box back minred">
                            <span>Black</span>
                            <span className="card-icon ms-1">
                              <span className="card-black ">
                                <img
                                  className="w-100 h-100"
                                  src={spade}
                                  alt="1"
                                />
                              </span>
                            </span>
                            <span className="card-icon ms-1">
                              <span className="card-black ">
                                <img
                                  className="w-100 h-100"
                                  src={club}
                                  alt="2"
                                />
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="casino-table-body">
                          <div className="casino-table-row ">
                            <div className="casino-nation-detail">
                              <div className="casino-nation-name">Dragon</div>
                            </div>
                            {reqRes?.data?.data?.t2[5]?.gstatus ===
                              "SUSPENDED" ||
                            reqRes?.data?.data?.t2[5]?.gstatus === "CLOSED" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds"></span>
                              </div>
                            ) : (
                              <span
                                onClick={() => {
                                  setShowdataModal(true);
                                  handleModal(
                                    reqRes?.data?.data?.t2[5]?.b1,
                                    reqRes?.data?.data?.t2[5]?.nat,
                                    10
                                  );
                                }}
                                className="casino-odds-box back">
                                {reqRes?.data?.data?.t2[5]?.b1}
                                <div>
                                  {thirtyTwoCardStack.map(
                                    (ele, index) =>
                                      ele.number === 8 && (
                                        <span
                                          className={` ${
                                            ele.number == 8 && ele.amount >= 0
                                              ? "success-1"
                                              : "zero"
                                          }`}
                                          key={index}>
                                          {ele.number == 8 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </div>
                              </span>
                            )}
                            {reqRes?.data?.data?.t2[5]?.gstatus ===
                              "SUSPENDED" ||
                            reqRes?.data?.data?.t2[5]?.gstatus === "CLOSED" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds"></span>
                              </div>
                            ) : (
                              <span
                                onClick={() => {
                                  setShowdataModal(true);
                                  handleModal(
                                    reqRes?.data?.data?.t2[6]?.b1,
                                    reqRes?.data?.data?.t2[6]?.nat,
                                    11
                                  );
                                }}
                                className="casino-odds-box back">
                                {reqRes?.data?.data?.t2[6]?.b1}
                                <div>
                                  {thirtyTwoCardStack.map(
                                    (ele, index) =>
                                      ele.number === 9 && (
                                        <span
                                          className={` ${
                                            ele.number == 9 && ele.amount >= 0
                                              ? "success-1"
                                              : "zero"
                                          }`}
                                          key={index}>
                                          {ele.number == 9 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </div>
                              </span>
                            )}
                            {/* <div className="casino-odds-box back suspended-box">
                              <span className="casino-odds">0</span>
                            </div>
                            <div className="casino-odds-box back suspended-box">
                              <span className="casino-odds">0</span>
                            </div> */}
                          </div>
                          <div className="casino-table-row">
                            <div className="casino-nation-detail">
                              <div className="casino-nation-name">Tiger</div>
                            </div>
                            {reqRes?.data?.data?.t2[14]?.gstatus ===
                              "SUSPENDED" ||
                            reqRes?.data?.data?.t2[14]?.gstatus === "CLOSED" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds"></span>
                              </div>
                            ) : (
                              <span
                                onClick={() => {
                                  setShowdataModal(true);
                                  handleModal(
                                    reqRes?.data?.data?.t2[14]?.b1,
                                    reqRes?.data?.data?.t2[14]?.nat,
                                    12
                                  );
                                }}
                                className="casino-odds-box back">
                                {reqRes?.data?.data?.t2[14]?.b1}
                                <div>
                                  {thirtyTwoCardStack.map(
                                    (ele, index) =>
                                      ele.number === 10 && (
                                        <span
                                          className={` ${
                                            ele.number == 10 && ele.amount >= 0
                                              ? "success-1"
                                              : "zero"
                                          }`}
                                          key={index}>
                                          {ele.number == 10 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </div>
                              </span>
                            )}
                            {reqRes?.data?.data?.t2[15]?.gstatus ===
                              "SUSPENDED" ||
                            reqRes?.data?.data?.t2[15]?.gstatus === "CLOSED" ? (
                              <div className="casino-odds-box back suspended-box">
                                <span className="casino-odds"></span>
                              </div>
                            ) : (
                              <span
                                onClick={() => {
                                  setShowdataModal(true);
                                  handleModal(
                                    reqRes?.data?.data?.t2[15]?.b1,
                                    reqRes?.data?.data?.t2[15]?.nat,
                                    13
                                  );
                                }}
                                className="casino-odds-box back">
                                {reqRes?.data?.data?.t2[15]?.b1}
                                <div>
                                  {thirtyTwoCardStack.map(
                                    (ele, index) =>
                                      ele.number === 11 && (
                                        <span
                                          className={` ${
                                            ele.number == 11 && ele.amount >= 0
                                              ? "success-1"
                                              : "zero"
                                          }`}
                                          key={index}>
                                          {ele.number == 11 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </div>
                              </span>
                            )}
                            {/* <div className="casino-odds-box back suspended-box">
                              <span className="casino-odds">0</span>
                            </div>
                            <div className="casino-odds-box back suspended-box">
                              <span className="casino-odds">0</span>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="casino-table-full-box dt1day-other-odds mt-3">
                      <div className="casino-table-header">
                        <div className="casino-nation-detail"></div>
                        <div className="casino-odds-box">
                          <span className="card-icon ms-1">
                            <span className="card-black">
                              <img className="w-100 h-100" src={spade} alt="" />
                            </span>
                          </span>
                        </div>
                        <div className="casino-odds-box">
                          <span className="card-icon ms-1">
                            <span className="card-red ">
                              <img className="w-100 h-100" src={heart} alt="" />
                            </span>
                          </span>
                        </div>
                        <div className="casino-odds-box">
                          <span className="card-icon ms-1">
                            <span className="card-black">
                              <img className="w-100 h-100" src={club} alt="" />
                            </span>
                          </span>
                        </div>
                        <div className="casino-odds-box">
                          <span className="card-icon ms-1">
                            <span className="card-red ">
                              <img
                                className="w-100 h-100"
                                src={diamond}
                                alt=""
                              />
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="casino-table-body">
                        <div className="casino-table-row">
                          <div className="casino-nation-detail">
                            <div className="casino-nation-name">Dragon</div>
                          </div>
                          {reqRes?.data?.data?.t2[7]?.gstatus === "SUSPENDED" ||
                          reqRes?.data?.data?.t2[7]?.gstatus === "CLOSED" ? (
                            <div className="casino-odds-box back suspended-box">
                              <span className="casino-odds"></span>
                            </div>
                          ) : (
                            <span
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[7]?.b1,
                                  reqRes?.data?.data?.t2[7]?.nat,
                                  14
                                );
                              }}
                              className="casino-odds-box back">
                              {reqRes?.data?.data?.t2[7]?.b1}
                              <div>
                                {thirtyTwoCardStack.map(
                                  (ele, index) =>
                                    ele.number === 12 && (
                                      <span
                                        className={` ${
                                          ele.number == 12 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                        key={index}>
                                        {ele.number == 12 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </span>
                          )}

                          {reqRes?.data?.data?.t2[8]?.gstatus === "SUSPENDED" ||
                          reqRes?.data?.data?.t2[8]?.gstatus === "CLOSED" ? (
                            <div className="casino-odds-box back suspended-box">
                              <span className="casino-odds"></span>
                            </div>
                          ) : (
                            <span
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[8]?.b1,
                                  reqRes?.data?.data?.t2[8]?.nat,
                                  15
                                );
                              }}
                              className="casino-odds-box back">
                              {reqRes?.data?.data?.t2[8]?.b1}
                              <div>
                                {thirtyTwoCardStack.map(
                                  (ele, index) =>
                                    ele.number === 13 && (
                                      <span
                                        className={` ${
                                          ele.number == 13 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                        key={index}>
                                        {ele.number == 13 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </span>
                          )}
                          {reqRes?.data?.data?.t2[10]?.gstatus ===
                            "SUSPENDED" ||
                          reqRes?.data?.data?.t2[10]?.gstatus === "CLOSED" ? (
                            <div className="casino-odds-box back suspended-box">
                              <span className="casino-odds"></span>
                            </div>
                          ) : (
                            <span
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[10]?.b1,
                                  reqRes?.data?.data?.t2[10]?.nat,
                                  16
                                );
                              }}
                              className="casino-odds-box back">
                              {reqRes?.data?.data?.t2[10]?.b1}
                              <div>
                                {thirtyTwoCardStack.map(
                                  (ele, index) =>
                                    ele.number === 14 && (
                                      <span
                                        className={` ${
                                          ele.number == 14 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                        key={index}>
                                        {ele.number == 14 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </span>
                          )}
                          {reqRes?.data?.data?.t2[9]?.gstatus === "SUSPENDED" ||
                          reqRes?.data?.data?.t2[9]?.gstatus === "CLOSED" ? (
                            <div className="casino-odds-box back suspended-box">
                              <span className="casino-odds"></span>
                            </div>
                          ) : (
                            <span
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[9]?.b1,
                                  reqRes?.data?.data?.t2[9]?.nat,
                                  17
                                );
                              }}
                              className="casino-odds-box back">
                              {reqRes?.data?.data?.t2[9]?.b1}
                              <div>
                                {thirtyTwoCardStack.map(
                                  (ele, index) =>
                                    ele.number === 15 && (
                                      <span
                                        className={` ${
                                          ele.number == 15 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                        key={index}>
                                        {ele.number == 15 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </span>
                          )}
                          {/* <div className="casino-odds-box back suspended-box">
                            <span className="casino-odds">0</span>
                          </div>
                          <div className="casino-odds-box back suspended-box">
                            <span className="casino-odds">0</span>
                          </div>
                          <div className="casino-odds-box back suspended-box">
                            <span className="casino-odds">0</span>
                          </div>
                          <div className="casino-odds-box back suspended-box">
                            <span className="casino-odds">0</span>
                          </div> */}
                        </div>
                        <div className="casino-table-row">
                          <div className="casino-nation-detail">
                            <div className="casino-nation-name">Tiger</div>
                          </div>
                          {reqRes?.data?.data?.t2[15]?.gstatus ===
                            "SUSPENDED" ||
                          reqRes?.data?.data?.t2[15]?.gstatus === "CLOSED" ? (
                            <div className="casino-odds-box back suspended-box">
                              <span className="casino-odds"></span>
                            </div>
                          ) : (
                            <span
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[15]?.b1,
                                  reqRes?.data?.data?.t2[15]?.nat,
                                  18
                                );
                              }}
                              className="casino-odds-box back">
                              {reqRes?.data?.data?.t2[15]?.b1}
                              <div>
                                {thirtyTwoCardStack.map(
                                  (ele, index) =>
                                    ele.number === 16 && (
                                      <span
                                        className={` ${
                                          ele.number == 16 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                        key={index}>
                                        {ele.number == 16 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </span>
                          )}
                          {reqRes?.data?.data?.t2[16]?.gstatus ===
                            "SUSPENDED" ||
                          reqRes?.data?.data?.t2[16]?.gstatus === "CLOSED" ? (
                            <div className="casino-odds-box back suspended-box">
                              <span className="casino-odds"></span>
                            </div>
                          ) : (
                            <span
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[16]?.b1,
                                  reqRes?.data?.data?.t2[16]?.nat,
                                  19
                                );
                              }}
                              className="casino-odds-box back">
                              {reqRes?.data?.data?.t2[16]?.b1}
                              <div>
                                {thirtyTwoCardStack.map(
                                  (ele, index) =>
                                    ele.number === 17 && (
                                      <span
                                        className={` ${
                                          ele.number == 17 && ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                        key={index}>
                                        {ele.number == 17 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </span>
                          )}
                          {reqRes?.data?.data?.t2[18]?.gstatus ===
                            "SUSPENDED" ||
                          reqRes?.data?.data?.t2[18]?.gstatus === "CLOSED" ? (
                            <div className="casino-odds-box back suspended-box">
                              <span className="casino-odds"></span>
                            </div>
                          ) : (
                            <span
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[18]?.b1,
                                  reqRes?.data?.data?.t2[18]?.nat,
                                  20
                                );
                              }}
                              className="casino-odds-box back">
                              {reqRes?.data?.data?.t2[18]?.b1}
                              {thirtyTwoCardStack.map(
                                (ele, index) =>
                                  ele.number === 18 && (
                                    <span
                                      className={` ${
                                        ele.number == 18 && ele.amount >= 0
                                          ? "success-1"
                                          : "zero"
                                      }`}
                                      key={index}>
                                      {ele.number == 18 && ele.amount}
                                    </span>
                                  )
                              )}
                            </span>
                          )}
                          {reqRes?.data?.data?.t2[17]?.gstatus ===
                            "SUSPENDED" ||
                          reqRes?.data?.data?.t2[17]?.gstatus === "CLOSED" ? (
                            <div className="casino-odds-box back suspended-box">
                              <span className="casino-odds"></span>
                            </div>
                          ) : (
                            <span
                              onClick={() => {
                                setShowdataModal(true);
                                handleModal(
                                  reqRes?.data?.data?.t2[17]?.b1,
                                  reqRes?.data?.data?.t2[17]?.nat,
                                  21
                                );
                              }}
                              className="casino-odds-box back">
                              {reqRes?.data?.data?.t2[17]?.b1}
                              {thirtyTwoCardStack.map(
                                (ele, index) =>
                                  ele.number === 19 && (
                                    <span
                                      className={` ${
                                        ele.number == 19 && ele.amount >= 0
                                          ? "success-1"
                                          : "zero"
                                      }`}
                                      key={index}>
                                      {ele.number == 19 && ele.amount}
                                    </span>
                                  )
                              )}
                            </span>
                          )}
                          {/* <div className="casino-odds-box back suspended-box">
                            <span className="casino-odds">0</span>
                          </div>
                          <div className="casino-odds-box back suspended-box">
                            <span className="casino-odds">0</span>
                          </div>
                          <div className="casino-odds-box back suspended-box">
                            <span className="casino-odds">0</span>
                          </div>
                          <div className="casino-odds-box back suspended-box">
                            <span className="casino-odds">0</span>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="Results-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Last 10 Results</p>
                  </div>
                </div>
                <div className="card rst-card py-1 d-flex">
                  <div className="rst-row d-flex">
                    {results?.data?.data?.map((result, index) => {
                      const item = reqRes?.data?.data?.t2.find(
                        (item) => item.sid === result?.result
                      );

                      return (
                        <div
                          onClick={() => {
                            setMidRound(result.mid);
                            CasinoGameResult_API(result.mid);
                            setShowresultModal(true);
                            setTrophy(item.nat[0]);
                          }}
                          className="rst-btn ms-1"
                          key={index}>
                          {item && <>{item.nat[0]}</>}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* <<<<<<<---------My-Bets-table-------------->>>>>>>>>> */}
              <div className="My-Bets-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Open Bets</p>
                  </div>
                </div>
                {HistoryTable}
                {/* <div className="card-table-inner-row row"></div> */}
              </div>
            </div>
          </div>

          <RightSidebar />
          {showModal && (
            <Modal
              id={id}
              name={name}
              betDetails={betDetails}
              setbetDetails={setbetDetails}
              setShowModal={setShowModal}
              realTime={realTime}
              setRealTimeData={setRealTimeData}
            />
          )}
          {showresultModal && <ResultsModal />}
          {/* {showDataModal && <DataModal />} */}
          {rulesModal && <Rules_Modal closeFunction={setRulesModal} />}
        </div>
      </div>
    </>
  );
}
