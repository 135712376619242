import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import a from "../../Media/10SS.png";
import b from "../../Media/3SS.png";
import { MdCancel } from "react-icons/md";
import { useParams } from "react-router-dom";
// import Rules_Modal from "../Rules_Modal";
import Modal from "../TeenPatti/Modal";
import { API } from "../../API/API";
import { fetchThirtyTwoCardStack } from "../../Redux/Slices/thirtyTwoCardGameSlice";

export default function SuperOver() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [rulesModal, setRulesModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [betDetails, setbetDetails] = useState({});
  const [showresultModal, setShowresultModal] = useState(false);
  const [showDataModal, setShowdataModal] = useState(false);
  const [trophy, setTrophy] = useState("");
  const [realTime, setRealTimeData] = useState(false);
  const [betIndex, setBetIndex] = useState("");
  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );
  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      console.log("CasinoGameResult dragon:", response.data);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };


  const SOCKET_URL = "https://gamecoin-socket.nakshtech.info/";

  useEffect(() => {
    // console.log("Odd socket before");
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("TeenPatti socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        console.log("parsed", parsedData[0]);
        // console.log("parsed_result", parsedData[0].resultUrlResponse);
        setResults(parsedData[0].resultUrlResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  async function ResultScheduler_API() {
    try {
      let res = await API.post("/Schulder_7CareGameLive", {
        gameid: id,
        username: uid
      },
        {
          headers: {
            Authorization: token,
          },
        });
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  const [history, setHistory] = useState([]);
  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);
    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id]);

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nat: nat,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.nat === betDetails.nat
      );

      // console.log("filter", filteredData, betDetails);
      filteredData[0]?.status === "ACTIVE" &&
      (betDetails.multipler === filteredData[0].l1 ||
        betDetails.multipler === filteredData[0].b1)
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }
  function checkValidFancy() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t3.filter(
        (item) => item.nat === betDetails.nat
      );

      // console.log("filter", filteredData, betDetails);
      filteredData[0]?.status === "ACTIVE" &&
      (betDetails.multipler === filteredData[0].l1 ||
        betDetails.multipler === filteredData[0].b1)
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  function checkValidFancyOne() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t4.filter(
        (item) => item.nat === betDetails.nat
      );

      // console.log("filter", filteredData, betDetails);
      filteredData[0]?.status === "ACTIVE" &&
      (betDetails.multipler === filteredData[0].l1 ||
        betDetails.multipler === filteredData[0].b1)
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    if (reqRes?.data?.data?.t2) {
      checkValid();
    }
    if (reqRes?.data?.data?.t3) {
      checkValidFancy();
    }
    if (reqRes?.data?.data?.t4) {
      checkValidFancyOne();
    }
  }, [showModal, realTime, reqRes?.data?.data, betDetails]);

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {history.length > 0 ? (
              history.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"
        ></div>
        <div className="results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound}</div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
              }}
            />
          </div>
          <div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div ">
                <div className="text-center">Player 8:27</div>
                <div className="d-flex">
                  <div className="gamecard-div mx-1">
                    <img src={b} className="img-fluid" alt="a" />
                  </div>
                  <div className="gamecard-div mx-1">
                    <img src={a} className="img-fluid" alt="a" />
                  </div>
                  <div className="gamecard-div mx-1">
                    <img src={b} className="img-fluid" alt="a" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="col-lg-10 ">
        <div className="row home mainhomeDev">
          <div className="col-lg-8 p-1">
            <div className="teenpatti-game-div pt-2 p-2">
              <div className="teenpatti-game-h d-flex justify-content-between  ">
                <div className=" px-2">
                  <p className=" mt-1 ">Super Over</p>
                </div>
                <div>
                  <p className="mt-1 px-2">
                    Round ID: {reqRes?.data?.data?.t1[0]?.mid || 0.0} | Left
                    Time: {reqRes?.data?.data?.t1[0]?.autotime || 0} |{" "}
                    <span onClick={() => setRulesModal(true)}>Rules</span>
                  </p>
                </div>
              </div>
              <div className="card  teenpatti-game-card p-1 mt-1">
                <div className="row">
                  <div className="col-10">
                    <div className="" style={{ height: "170%", width: "120%" }}>
                      <iframe
                        height="100%"
                        width="100%"
                        src={`https://battlemoney.live/iframe/${id}`}
                        title=""
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-end">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>

              {/* <-------------------------------Back-lay-table----------> */}
              {reqRes?.data?.data?.t2 && (
                <div>
                  <div
                    className="heading-row row align-items-center"
                    style={{ height: "30px", backgroundColor: "grey" }}
                  >
                    <div className="col-12 d-flex align-items-center ps-2">
                      Bookmaker Market
                    </div>
                  </div>
                  <div className="table-h-div row">
                    <div className="col-6 d-flex align-items-center ps-2">
                      {/* Bookmaker Market */}
                      Min: 100.00 Max: 5L
                    </div>
                    <div className="col-3 d-flex align-items-center justify-content-center px-2">
                      Back
                    </div>
                    <div className="col-3 d-flex align-items-center justify-content-center ">
                      Lay
                    </div>
                  </div>
                  <div className="card-table-inner-row row">
                    <div className="col-6 d-flex align-items-center ps-2">
                      {reqRes?.data?.data?.t2[0]?.nat}
                      {/* ENG <span className="success-o ms-1"> 0</span> */}
                    </div>
                    {/* <div className="col-3 d-flex align-items-center justify-content-center px-2"> */}
                    {reqRes?.data?.data?.t2[0]?.status === "SUSPENDED" ||
                    reqRes?.data?.data?.t2[0]?.status === "CLOSED" ? (
                      <div className="col-3 d-flex align-items-center justify-content-center px-2">
                        <button className="back-btn">
                          {reqRes?.data?.data?.t2[0]?.status}
                        </button>
                      </div>
                    ) : (
                      // <div className="casino-odds-box back suspended-box">
                      //   <span className="casino-odds"></span>
                      // </div>
                      <div className="col-3 d-flex align-items-center justify-content-center px-2">
                        <button
                          onClick={() => {
                            setShowdataModal(true);
                            handleModal(
                              reqRes?.data?.data?.t2[0]?.b1,
                              reqRes?.data?.data?.t2[0]?.nat,
                              1
                            );
                          }}
                          className="back-btn"
                        >
                          {reqRes?.data?.data?.t2[0]?.b1}
                        </button>
                      </div>
                    )}
                    {/* <button
                      onClick={() => {
                        setShowdataModal(true);
                      }}
                      className="back-btn"
                    >
                      Suspended
                    </button> */}
                    {/* </div> */}
                    {reqRes?.data?.data?.t2[0]?.status === "SUSPENDED" ||
                    reqRes?.data?.data?.t2[0]?.status === "CLOSED" ? (
                      <div className="col-3 d-flex align-items-center justify-content-center ">
                        <button className="lay-btn">
                          {" "}
                          {reqRes?.data?.data?.t2[0]?.status}
                        </button>
                      </div>
                    ) : (
                      // <div className="casino-odds-box back suspended-box">
                      //   <span className="casino-odds"></span>
                      // </div>
                      <div className="col-3 d-flex align-items-center justify-content-center ">
                        <button
                          onClick={() => {
                            setShowdataModal(true);
                            handleModal(
                              reqRes?.data?.data?.t2[0]?.l1,
                              reqRes?.data?.data?.t2[0]?.nat,
                              3
                            );
                          }}
                          className="lay-btn"
                        >
                          {reqRes?.data?.data?.t2[0]?.l1}
                        </button>
                      </div>
                    )}
                    {/* <div className="col-3 d-flex align-items-center justify-content-center ">
                    <button className="lay-btn">Suspended</button>
                  </div> */}
                  </div>
                  <div className="card-table-inner-row row">
                    <div className="col-6 d-flex align-items-center ps-2">
                      {reqRes?.data?.data?.t2[1]?.nat}
                      {/* IND <span className="success-o ms-1"> 0</span> */}
                    </div>
                    {/* <div className="col-3 d-flex align-items-center justify-content-center px-2"> */}
                    {reqRes?.data?.data?.t2[0]?.status === "SUSPENDED" ||
                    reqRes?.data?.data?.t2[0]?.status === "CLOSED" ? (
                      <div className="col-3 d-flex align-items-center justify-content-center px-2">
                        <button className="back-btn">
                          {reqRes?.data?.data?.t2[0]?.status}
                        </button>
                      </div>
                    ) : (
                      // <div className="casino-odds-box back suspended-box">
                      //   <span className="casino-odds"></span>
                      // </div>
                      <div className="col-3 d-flex align-items-center justify-content-center px-2">
                        <button
                          onClick={() => {
                            setShowdataModal(true);
                            handleModal(
                              reqRes?.data?.data?.t2[1]?.b1,
                              reqRes?.data?.data?.t2[1]?.nat,
                              2
                            );
                          }}
                          className="back-btn"
                        >
                          {reqRes?.data?.data?.t2[1]?.b1}
                        </button>
                      </div>
                    )}
                    {reqRes?.data?.data?.t2[1]?.status === "SUSPENDED" ||
                    reqRes?.data?.data?.t2[1]?.status === "CLOSED" ? (
                      <div className="col-3 d-flex align-items-center justify-content-center px-2">
                        <button className="lay-btn">
                          {reqRes?.data?.data?.t2[1]?.status}
                        </button>
                      </div>
                    ) : (
                      <div className="col-3 d-flex align-items-center justify-content-center ">
                        <button
                          onClick={() => {
                            setShowdataModal(true);
                            handleModal(
                              reqRes?.data?.data?.t2[1]?.l1,
                              reqRes?.data?.data?.t2[1]?.nat,
                              4
                            );
                          }}
                          className="lay-btn"
                        >
                          {reqRes?.data?.data?.t2[1]?.l1}
                        </button>
                      </div>
                    )}
                    {/* <button className="lay-btn">Suspended</button> */}
                    {/* </div> */}
                  </div>
                </div>
              )}

              {reqRes?.data?.data?.t3 && (
                <div>
                  <div
                    className="heading-row row align-items-center"
                    style={{ height: "30px", backgroundColor: "grey" }}
                  >
                    <div className="col-12 d-flex align-items-center ps-2">
                      Fancy
                    </div>
                  </div>

                  <div className="table-h-div row">
                    <div className="col-6 d-flex align-items-center ps-2">
                      {/* Fancy */}
                    </div>
                    <div className="col-3 d-flex align-items-center justify-content-center px-2">
                      No
                    </div>
                    <div className="col-3 d-flex align-items-center justify-content-center">
                      Yes
                    </div>
                  </div>

                  {reqRes?.data?.data?.t3[0] && (
                    <div className="card-table-inner-row row">
                      <div className="col-6 d-flex align-items-center ps-2">
                        {reqRes?.data?.data?.t3[0]?.nat}
                      </div>
                      {reqRes?.data?.data?.t3[0]?.status === "SUSPENDED" ||
                      reqRes?.data?.data?.t3[0]?.status === "CLOSED" ? (
                        <div className="col-3 d-flex align-items-center justify-content-center px-2">
                          <button className="back-btn">
                            {reqRes?.data?.data?.t3[0]?.status}
                          </button>
                        </div>
                      ) : (
                        <div className="col-3 d-flex align-items-center justify-content-center px-2">
                          <button
                            onClick={() => {
                              setShowdataModal(true);
                              handleModal(
                                reqRes?.data?.data?.t3[0]?.b1,
                                reqRes?.data?.data?.t3[0]?.nat,
                                5
                              );
                            }}
                            className="back-btn"
                          >
                            {reqRes?.data?.data?.t3[0]?.b1}
                          </button>
                        </div>
                      )}
                      {reqRes?.data?.data?.t3[0]?.status === "SUSPENDED" ||
                      reqRes?.data?.data?.t3[0]?.status === "CLOSED" ? (
                        <div className="col-3 d-flex align-items-center justify-content-center px-2">
                          <button className="lay-btn">
                            {reqRes?.data?.data?.t3[0]?.status}
                          </button>
                        </div>
                      ) : (
                        <div className="col-3 d-flex align-items-center justify-content-center px-2">
                          <button
                            onClick={() => {
                              setShowdataModal(true);
                              handleModal(
                                reqRes?.data?.data?.t3[0]?.l1,
                                reqRes?.data?.data?.t3[0]?.nat,
                                6
                              );
                            }}
                            className="lay-btn"
                          >
                            {reqRes?.data?.data?.t3[0]?.l1}
                          </button>
                        </div>
                      )}
                    </div>
                  )}

                  {reqRes?.data?.data?.t3[1] && (
                    <div className="card-table-inner-row row">
                      <div className="col-6 d-flex align-items-center ps-2">
                        {reqRes?.data?.data?.t3[1]?.nat}
                      </div>
                      {reqRes?.data?.data?.t3[1]?.status === "SUSPENDED" ||
                      reqRes?.data?.data?.t3[1]?.status === "CLOSED" ? (
                        <div className="col-3 d-flex align-items-center justify-content-center px-2">
                          <button className="back-btn">
                            {reqRes?.data?.data?.t3[1]?.status}
                          </button>
                        </div>
                      ) : (
                        <div className="col-3 d-flex align-items-center justify-content-center px-2">
                          <button
                            onClick={() => {
                              setShowdataModal(true);
                              handleModal(
                                reqRes?.data?.data?.t3[1]?.b1,
                                reqRes?.data?.data?.t3[1]?.nat,
                                7
                              );
                            }}
                            className="back-btn"
                          >
                            {reqRes?.data?.data?.t3[1]?.b1}
                          </button>
                        </div>
                      )}
                      {reqRes?.data?.data?.t3[1]?.status === "SUSPENDED" ||
                      reqRes?.data?.data?.t3[1]?.status === "CLOSED" ? (
                        <div className="col-3 d-flex align-items-center justify-content-center px-2">
                          <button className="lay-btn">
                            {reqRes?.data?.data?.t3[1]?.status}
                          </button>
                        </div>
                      ) : (
                        <div className="col-3 d-flex align-items-center justify-content-center px-2">
                          <button
                            onClick={() => {
                              setShowdataModal(true);
                              handleModal(
                                reqRes?.data?.data?.t3[1]?.l1,
                                reqRes?.data?.data?.t3[1]?.nat,
                                8
                              );
                            }}
                            className="lay-btn"
                          >
                            {reqRes?.data?.data?.t3[1]?.l1}
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}

              {reqRes?.data?.data?.t4 && (
                <div className="row">
                  <div
                    className="heading-row row align-items-center"
                    style={{ height: "30px", backgroundColor: "grey" }}
                  >
                    <div className="col-12 d-flex align-items-center ps-2">
                      Fancy 1
                    </div>
                  </div>

                  {(() => {
                    const t4 = reqRes?.data?.data?.t4;
                    const rowsPerTable = t4.length === 6 ? 3 : 4;

                    return [0, 1].map((tableIndex) => (
                      <div key={tableIndex} className="col-6">
                        <div
                          className="table-h-div row align-items-center"
                          style={{ height: "30px" }}
                        >
                          <div className="col-6 d-flex align-items-center ps-2">
                            {/* Empty column to balance layout */}
                          </div>
                          <div className="col-3 d-flex align-items-center justify-content-center px-2">
                            Back
                          </div>
                          <div className="col-3 d-flex align-items-center justify-content-center">
                            Lay
                          </div>
                        </div>

                        {t4
                          .slice(
                            tableIndex * rowsPerTable,
                            (tableIndex + 1) * rowsPerTable
                          )
                          .map((item, index) => {
                            const ruleNumberBase =
                              tableIndex * rowsPerTable * 2 + 9;
                            const backRuleNumber = ruleNumberBase + index * 2;
                            const layRuleNumber =
                              ruleNumberBase + index * 2 + 1;

                            return (
                              <div
                                key={index}
                                className="card-table-inner-row row"
                              >
                                <div className="col-6 d-flex align-items-center ps-2">
                                  {item?.nat}
                                </div>

                                {item?.status === "SUSPENDED" ||
                                item?.status === "CLOSED" ? (
                                  <div className="col-3 d-flex align-items-center justify-content-center px-2">
                                    <button className="back-btn">
                                      {item?.status}
                                    </button>
                                  </div>
                                ) : (
                                  <div className="col-3 d-flex align-items-center justify-content-center px-2">
                                    <button
                                      onClick={() => {
                                        setShowdataModal(true);
                                        handleModal(
                                          item?.b1,
                                          item?.nat,
                                          backRuleNumber
                                        );
                                      }}
                                      className="back-btn"
                                    >
                                      {item?.b1}
                                    </button>
                                  </div>
                                )}

                                {item?.status === "SUSPENDED" ||
                                item?.status === "CLOSED" ? (
                                  <div className="col-3 d-flex align-items-center justify-content-center px-2">
                                    <button className="lay-btn">
                                      {item?.status}
                                    </button>
                                  </div>
                                ) : (
                                  <div className="col-3 d-flex align-items-center justify-content-center px-2">
                                    <button
                                      onClick={() => {
                                        setShowdataModal(true);
                                        handleModal(
                                          item?.l1,
                                          item?.nat,
                                          layRuleNumber
                                        );
                                      }}
                                      className="lay-btn"
                                    >
                                      {item?.l1}
                                    </button>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    ));
                  })()}
                </div>
              )}

              <div className="Results-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Last 10 Results</p>
                  </div>
                </div>
                <div className="card rst-card py-1 d-flex">
                  <div className="rst-row d-flex">
                    {results?.data?.data?.map((result, index) => {
                      const item = reqRes?.data?.data?.t2.find(
                        (item) => item.sid === result?.result
                      );

                      return (
                        <div
                          onClick={() => {
                            setMidRound(result.mid);
                            CasinoGameResult_API(result.mid);
                            setShowresultModal(true);
                            setTrophy(item.nat[0]);
                          }}
                          className="rst-btn ms-1"
                          key={index}
                        >
                          {item && <>{item.nat[0]}</>}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* <<<<<<<---------My-Bets-table-------------->>>>>>>>>> */}
              <div className="My-Bets-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Open Bets</p>
                  </div>
                </div>
                {HistoryTable}
                {/* <div className="card-table-inner-row row"></div> */}
              </div>
            </div>
          </div>

          <RightSidebar />
          {showModal && (
            <Modal
              id={id}
              name={name}
              betDetails={betDetails}
              setbetDetails={setbetDetails}
              setShowModal={setShowModal}
              realTime={realTime}
              setRealTimeData={setRealTimeData}
            />
          )}
          {showresultModal && <ResultsModal />}
          {/* {showDataModal && <DataModal />} */}
          {/* {rulesModal && <Rules_Modal closeFunction={setRulesModal} />} */}
        </div>
      </div>
    </>
  );
}
